import { Container, Flex } from "@chakra-ui/react";
import { firebase } from "@flowby/firebase";
import { useEffect } from "react";
import { IoExitOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Header from "../components/shared/Header";

export default function IndexPage({
	store,
	admin,
}: { store: string; admin: boolean }) {
	const navigate = useNavigate();
	useEffect(() => {
		if (store) {
			navigate(`/${store}`);
		} else if (admin) {
			navigate("/flowby-admin");
		}
	}, [store, admin]);
	return (
		<Flex direction="column" minH={"90vh"}>
			<Header
				leftComponent={{
					text: "Logout",
					icon: <IoExitOutline style={{ transform: "scaleX(-1)" }} size={24} />,
					onClick: () => {
						firebase.auth.signOut();
						navigate("/");
					},
				}}
				rightComponent={null}
			/>
			<Container />
		</Flex>
	);
}
