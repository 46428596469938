import {
	Box,
	Button,
	Center,
	Flex,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import { useRef, useState } from "react";
import { QRCode as LogoQRCode } from "react-qrcode-logo";
import ModalContainer from "./ModalContainer";
import { useToast } from "./Toast";

const logo = "/android-chrome-512x512.png";

export default function QRCode({
	store,
	queueOrGroup,
	isGroup,
}: {
	store: string;
	queueOrGroup: string;
	isGroup: boolean;
}) {
	const downloaderDisclosure = useDisclosure();
	const suffix = isGroup ? `/g/${queueOrGroup}` : `/q/${queueOrGroup}`;
	const url = `${process.env.REACT_APP_ENV === "dev" ? "https://dev.go.flowby.io" : "https://go.flowby.io"}/${store}/${suffix}`;
	return (
		<Box>
			<ModalContainer
				isOpen={downloaderDisclosure.isOpen}
				onClose={downloaderDisclosure.onClose}
				header="Download QR Code"
				content={
					<QRDownloader url={url} store={store} queueOrGroup={queueOrGroup} />
				}
			/>
			<Center alignItems="center">
				<Box>
					<QRWithLogo url={url} logo={logo} />
				</Box>
			</Center>
			<Center>
				<Button size="md" onClick={downloaderDisclosure.onOpen}>
					Download
				</Button>
			</Center>
		</Box>
	);
}

const QRDownloader = ({
	url,
	store,
	queueOrGroup,
}: { url: string; store: string; queueOrGroup: string }) => {
	const componentRef = useRef<HTMLDivElement>(null);
	const toast = useToast();
	const [size, setSize] = useState(1500);
	const [custom, setCustom] = useState(false);
	const downloadImage = async () => {
		if (componentRef.current) {
			const canvas = await html2canvas(componentRef.current);
			const link = document.createElement("a");
			link.download = `${store}-${queueOrGroup}`;
			link.href = canvas.toDataURL();
			link.click();
		}
	};
	// print at 300 dpi is 3508x2480 to fill a4 paper
	const convertDPI = 11.81;

	return (
		<Stack align="center" spacing={5}>
			<Text>
				Choose your size and click the download button to download an image of
				your QR code, you can then insert it into your print design and print.
			</Text>
			<Text>
				The recommended print size for optimal quality on an A4 paper is
				displayed below, if you need bigger or smaller image sizes, click the
				custom size button and input your desired size.
			</Text>
			<Text>
				If you have questions or need assistance designing your QR code page,
				contact us at hello@flowby.io.
			</Text>
			{/* Need to hide the QR code we base the download size on */}
			<Box pos="absolute" top={-20000} ref={componentRef}>
				<QRWithLogo size={size} url={url} logo={logo} />
			</Box>
			<Box>
				<Text color="gray.700" fontSize="sm">
					A4 paper size
				</Text>
				<Flex
					w={210}
					h={297}
					borderWidth={1}
					borderColor="gray.200"
					justify="center"
					align="center"
				>
					{size <= 2000 ? (
						<QRWithLogo size={size / convertDPI} url={url} logo={logo} />
					) : (
						<Text px={5} textAlign="center" color="gray.700" fontSize="sm">
							Too large to display in A4 format.
						</Text>
					)}
				</Flex>
			</Box>
			<RadioGroup
				onChange={(value) => {
					if (value === "custom") {
						setCustom(true);
					} else {
						setCustom(false);
						setSize(Number(value));
					}
				}}
				value={custom ? "custom" : size.toString()}
			>
				<Stack direction="row">
					<Radio value="1000">Small</Radio>
					<Radio value="1500">Medium</Radio>
					<Radio value="2000">Large</Radio>
					<Radio value={"custom"}>Custom</Radio>
				</Stack>
			</RadioGroup>
			<Text color="gray.700" fontSize="sm" textAlign="center">
				{custom ? (
					<Input
						w={100}
						value={size}
						onChange={(event) => {
							const valueNumber = Number(event.target.value);
							if (valueNumber > 10000) {
								toast("warning", "Maximum size is 10000x10000 pixels.");
								setSize(10000);
							} else if (Number.isNaN(valueNumber)) {
								toast("warning", "Only numbers are allowed.");
								setSize(1500);
							} else {
								setSize(Number(event.target.value));
							}
						}}
					/>
				) : (
					`${size}`
				)}
				{`x${size} pixels`}
			</Text>
			<Button onClick={downloadImage}>Download</Button>
		</Stack>
	);
};

const QRWithLogo = ({
	size = 150,
	url,
	logo,
}: { size?: number; url: string; logo: string }) => (
	<LogoQRCode
		size={size}
		value={url}
		logoImage={logo}
		quietZone={10}
		eyeRadius={[
			[10, 10, 0, 10], // top/left eye
			[10, 10, 10, 0], // top/right eye
			[10, 0, 10, 10], // bottom/left
		]}
	/>
);
