import {
	Box,
	Button,
	Grid,
	GridItem,
	Heading,
	Image,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { db } from "@flowby/firebase";
import { useState } from "react";
import { IoArrowBack, IoHelpBuoyOutline, IoPeople } from "react-icons/io5";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import QueueView from "../components/QueueView/QueueView";
import Settings from "../components/QueueView/Settings";
import Loader from "../components/shared/Loader";
import ModalContainer from "../components/shared/ModalContainer";
import PageLayout from "../components/shared/PageLayout";
import QueueStatusBadge from "../components/shared/QueueStatusBadge";
import Support from "../components/shared/Support";
import { useCollData, useDocData } from "../libs/firebaseHooks";

export default function QueuePage() {
	const settingsDisclosure = useDisclosure();
	const supportDisclosure = useDisclosure();
	const [logoError, setLogoError] = useState(false);

	const navigate = useNavigate();
	// TODO: Queue can be undefined here
	const params = useParams();
	const store = params.store as string;
	const queue = params.queue as string;

	const [queueData, queueDataLoading] = useDocData(
		db.getQueueRef(store, queue),
		[store, queue],
	);
	const [groupsData] = useCollData(db.getGroupsRef(store), [store]);

	if (queueDataLoading) {
		return <Loader fullscreen={true} />;
	}

	let content = null;

	if (!queueData) {
		content = (
			<Stack textAlign="center" spacing={4} pt={10}>
				<Text fontSize="xl">{`Could not find the queue "${queue}".`}</Text>
				<RouterLink to={`/${store}`}>
					<Box>
						<Button>Go Back</Button>
					</Box>
				</RouterLink>
			</Stack>
		);
	} else if (queueData.state.status === "closed") {
		content = (
			<Stack textAlign="center" spacing={4} pt={10}>
				<Text fontSize="xl">The queue is closed.</Text>
				<Box>
					<Button onClick={() => db.openQueue(store, queue, queueData)}>
						Open Queue
					</Button>
				</Box>
			</Stack>
		);
	} else {
		content = (
			<QueueView
				store={store}
				queue={queue}
				queueData={queueData}
				groupsData={groupsData}
			/>
		);
	}

	return (
		<PageLayout
			leftNavigation={{
				text: "Back",
				icon: (
					<Box ml={-1} mr={-1}>
						<IoArrowBack size={24} />
					</Box>
				),
				onClick: () => navigate(`/${store}`),
			}}
			logo={
				!logoError ? (
					<Image
						role="presentation"
						margin="auto"
						maxH={43}
						fit="contain"
						src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === "dev" ? "flowby-io-dev" : "flowby-io"}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
						onError={() => setLogoError(true)}
					/>
				) : undefined
			}
			rightNavigation={{
				text: "Support",
				icon: <IoHelpBuoyOutline size={24} />,
				onClick: supportDisclosure.onOpen,
			}}
		>
			<Stack flex={1} spacing={0}>
				{queueData && (
					<ModalContainer
						isOpen={settingsDisclosure.isOpen}
						onClose={settingsDisclosure.onClose}
						header="Settings"
						content={
							<Settings
								store={store}
								queue={queue}
								queueData={queueData}
								onFinish={settingsDisclosure.onClose}
							/>
						}
					/>
				)}
				<ModalContainer
					isOpen={supportDisclosure.isOpen}
					onClose={supportDisclosure.onClose}
					header="Support"
					content={<Support />}
				/>
				<Grid templateColumns="repeat(7, 1fr)" maxW="xl" w="100%" mx="auto">
					<GridItem colSpan={1} textAlign="left">
						<Button
							ml={2}
							size="xs"
							variant="outline"
							onClick={() => navigate(`/${store}/q/${queue}/queuers`)}
						>
							<IoPeople size={22} />
						</Button>
					</GridItem>
					<GridItem colSpan={5}>
						<Heading textAlign="center">
							{queueData ? queueData.displayName : queue}
						</Heading>
					</GridItem>
					<GridItem colSpan={1} textAlign="right" pr={2}>
						{queueData && (
							<Button
								size="xs"
								variant="outline"
								onClick={settingsDisclosure.onOpen}
							>
								<QueueStatusBadge status={queueData.state.status} />
							</Button>
						)}
					</GridItem>
				</Grid>
				{content}
			</Stack>
		</PageLayout>
	);
}
