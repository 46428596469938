import { Flex, Heading } from "@chakra-ui/react";

export default function FlowbyError({
	text,
	fullscreen = false,
}: { text?: string; fullscreen?: boolean }) {
	return (
		<Flex
			pt={fullscreen ? "40vh" : 0}
			alignItems="center"
			justifyContent="center"
			textAlign="center"
		>
			<Heading size="md">{text || "Something went wrong!"}</Heading>
		</Flex>
	);
}
