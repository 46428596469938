import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Input,
	Stack,
	Text,
	type UseDisclosureReturn,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import slugify from "slugify";

export default function ConfirmAlert({
	disclosure,
	headerText,
	questionText,
	noText,
	yesText,
	yesLoading = false,
	yesAction,
	validateString,
	validatePlaceholder,
}: {
	disclosure: UseDisclosureReturn;
	headerText: string;
	questionText: string;
	noText: string;
	yesText: string;
	yesLoading?: boolean;
	yesAction(): void;
	validateString?: string;
	validatePlaceholder?: string;
}) {
	const noRef = useRef(null);
	const [validationString, setValidationString] = useState("");
	const valid = validateString ? validationString === validateString : true;
	return (
		<AlertDialog
			isOpen={disclosure.isOpen}
			onClose={disclosure.onClose}
			leastDestructiveRef={noRef}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{headerText}
					</AlertDialogHeader>

					<AlertDialogBody>
						<Stack>
							<Text>{questionText}</Text>
							{validateString && (
								<Input
									placeholder={validatePlaceholder}
									isInvalid={!valid}
									onChange={(e) => setValidationString(e.target.value)}
								/>
							)}
						</Stack>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							data-testid={`${slugify(headerText, { lower: true })}-no-button`}
							ref={noRef}
							variant="outline"
							onClick={disclosure.onClose}
						>
							{noText}
						</Button>
						<Button
							data-testid={`${slugify(headerText, { lower: true })}-yes-button`}
							disabled={!valid}
							isLoading={yesLoading}
							onClick={yesAction}
							ml={3}
						>
							{yesText}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
}
