import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkStatus, print } from "../../libs/printer";
import { useToast } from "../shared/Toast";

export default function KioskManualNumber({
	store,
	queue,
	queueName,
	queueNumberId,
	manualQueueNumber,
	onFinish,
	printerPortName,
}: {
	store: string;
	queue: string | null;
	queueName: string | null;
	queueNumberId: string | null;
	manualQueueNumber: number | string;
	onFinish: () => void;
	printerPortName: string | null;
}) {
	const { t } = useTranslation();
	const [timeLeft, setTimeLeft] = useState(20);
	const [printed, setPrinted] = useState(false);
	const [printerError, setPrinterError] = useState(false);
	const toast = useToast();

	useEffect(() => {
		if (timeLeft <= 0) {
			onFinish();
			return;
		}
		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	useEffect(() => {
		if (manualQueueNumber && queueNumberId && queueName && queue) {
			if (printerPortName && !printed) {
				setPrinted(true);
				print({
					printerPort: printerPortName,
					footer: t("scan-to-switch-to-digital"),
					queueNumber: manualQueueNumber.toString(),
					queueNumberId,
					store,
					queue,
					queueName,
				})
					.then((res) => {
						if (res === "Success!") {
							onFinish();
						} else {
							throw new Error();
						}
					})
					.catch(() => {
						setPrinterError(true);
						checkStatus(printerPortName)
							.then((status) => {
								if (status.coverOpen) {
									toast(
										"warning",
										"Printer cover is open. Please notify the staff.",
										"printer",
									);
								} else if (status.receiptPaperEmpty) {
									toast(
										"warning",
										"Printer paper is empty. Please notify the staff.",
										"printer",
									);
								} else if (status.offline) {
									toast(
										"warning",
										"Printer is offline. Please notify the staff.",
										"printer",
									);
								} else {
									toast(
										"warning",
										"Printer is disconnected. Please notify the staff.",
										"printer",
									);
								}
							})
							.catch(() => {
								setPrinterError(true);
								toast(
									"warning",
									"Printer error. Please notify the staff.",
									"printer",
								);
							});
					});
			}
		}
	}, [manualQueueNumber, queueNumberId, queueName, queue]);

	return (
		<Container data-testid="kiosk-manual-number" mb={2}>
			<Stack textAlign="center" align="center" py={{ base: 4 }} spacing={4}>
				<Heading fontSize="5xl">{t("your-queue-number")}</Heading>
				<Box bgColor="yellow.300" borderRadius="0.375rem" px={8}>
					<Text
						data-testid="kiosk-manual-number-text"
						fontWeight="bold"
						fontSize="11rem"
					>
						{manualQueueNumber ? manualQueueNumber : "..."}
					</Text>
				</Box>
				{(!printerPortName || printerError) && (
					<>
						<Text fontWeight="bold" fontSize="4xl">
							<span style={{ textDecoration: "underline" }}>
								{t("kiosk-no-printed-queue-number")}
							</span>
							<br />
							{t("kiosk-memorize-manual-number")}
						</Text>
						<Button
							id="kiosk-manual-number-close"
							height={16}
							fontSize={22}
							pr={8}
							pl={8}
							onClick={onFinish}
						>
							{t("kiosk-close-manual-number-popup")}
						</Button>
					</>
				)}
				<Text fontSize="2xl">
					{timeLeft <= 10 &&
						`${t("kiosk-manual-number-auto-close")} ${timeLeft}`}
				</Text>
			</Stack>
		</Container>
	);
}
