import { useEffect, useState } from "react";

export const useOnlineStatus = () => {
	const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

	useEffect(() => {
		window.addEventListener("offline", () => {
			setOnlineStatus(false);
		});
		window.addEventListener("online", () => {
			setOnlineStatus(true);
		});

		return () => {
			window.removeEventListener("offline", () => {
				setOnlineStatus(false);
			});
			window.removeEventListener("online", () => {
				setOnlineStatus(true);
			});
		};
	}, []);

	return onlineStatus;
};
