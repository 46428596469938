import { QRCode as LogoQRCode } from "react-qrcode-logo";

export const QRWithLogo = ({
	size = 150,
	url,
	logo,
}: { size?: number; url: string; logo: string }) => (
	<LogoQRCode
		size={size}
		value={url}
		logoImage={logo}
		quietZone={10}
		eyeRadius={[
			[10, 10, 0, 10], // top/left eye
			[10, 10, 10, 0], // top/right eye
			[10, 0, 10, 10], // bottom/left
		]}
	/>
);
