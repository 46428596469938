import {
	Button,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Switch,
	Text,
	Textarea,
} from "@chakra-ui/react";
import {
	Field,
	type FieldInputProps,
	Form,
	Formik,
	type FormikHelpers,
	type FormikProps,
} from "formik";
import type { CSSProperties } from "react";
import slugify from "slugify";

export type FormikFields = {
	type: string; // this is to support other types of form input in the future
	id: string;
	label?: string;
	helperText?: string;
	placeholder?: string;
	initialValue?: any; // we need intial value on all fields, otherwise formik will not show errors on submit
	value?: string; // used to control value from the outside
	validation?: (value: string) => string | undefined;
	onChange?: (
		e: React.ChangeEvent<any>,
		formikProps: FormikProps<{ [key: string]: string }>,
	) => void;
	formControlStyle?: CSSProperties;
	formHelperTextStyle?: CSSProperties;
}[];

export default function FormikForm({
	fields,
	submitButtonText,
	onSubmit,
	inlineSubmitButton = false,
}: {
	fields: FormikFields;
	submitButtonText?: string;
	inlineSubmitButton?: boolean;
	onSubmit?: (values: { [key: string]: any }) => Promise<void>;
}) {
	const initialValues = fields.reduce(
		(acc: { [key: string]: string }, field) => {
			if (field.initialValue) {
				acc[field.id] = field.initialValue;
			} else {
				acc[field.id] = "";
			}
			return acc;
		},
		{},
	);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values: any, actions: FormikHelpers<any>) => {
				onSubmit && (await onSubmit(values));
				actions.setSubmitting(false);
			}}
		>
			{(props) => (
				<Form>
					<Stack direction={inlineSubmitButton ? "row" : "column"}>
						{fields.map((f) => (
							<Field key={f.id} name={f.id} validate={f.validation}>
								{({
									field,
									form,
								}: {
									field: FieldInputProps<any>;
									form: FormikProps<any>;
								}) => {
									const rawFormError = form.errors[f.id];
									const formError =
										typeof rawFormError === "string" ||
										rawFormError instanceof String
											? rawFormError
											: undefined;
									return (
										<FormControl
											onSelect={() =>
												f.value && props.setFieldValue(f.id, f.value)
											}
											style={{ ...f.formControlStyle }}
											isInvalid={Boolean(
												form.errors[f.id] && form.touched[f.id],
											)}
										>
											{f.label && <FormLabel>{f.label}</FormLabel>}
											{f.helperText && (
												<FormHelperText style={f.formHelperTextStyle}>
													{f.helperText}
												</FormHelperText>
											)}
											{f.type === "textInput" && (
												<InputGroup>
													<Input
														{...field}
														onChange={(e: React.ChangeEvent<any>) => {
															f.onChange
																? f.onChange(e, props)
																: props.handleChange(e);
														}}
														placeholder={f.placeholder || ""}
													/>
													{inlineSubmitButton && (
														<InputRightElement width="4.5rem">
															<Button
																size="md"
																colorScheme="teal"
																isLoading={props.isSubmitting}
																type="submit"
															>
																{submitButtonText || "Submit"}
															</Button>
														</InputRightElement>
													)}
												</InputGroup>
											)}
											{f.type === "textArea" && (
												<Textarea
													{...field}
													onChange={(e: React.ChangeEvent<any>) => {
														f.onChange
															? f.onChange(e, props)
															: props.handleChange(e);
													}}
													placeholder={f.placeholder || ""}
												/>
											)}
											{f.type === "label" && f.value && <Text>{f.value}</Text>}
											{f.type === "switch" && (
												<Switch
													defaultChecked={f.initialValue}
													{...field}
													onChange={(e: React.ChangeEvent<any>) => {
														f.onChange
															? f.onChange(e, props)
															: props.handleChange(e);
													}}
												/>
											)}
											<FormErrorMessage>{formError}</FormErrorMessage>
										</FormControl>
									);
								}}
							</Field>
						))}
					</Stack>
					{!inlineSubmitButton && onSubmit && (
						<Stack>
							<Button
								data-testid={`${slugify(submitButtonText || "Submit", { lower: true })}-form-button`}
								mt={4}
								colorScheme="teal"
								isLoading={props.isSubmitting}
								type="submit"
							>
								{submitButtonText || "Submit"}
							</Button>
						</Stack>
					)}
				</Form>
			)}
		</Formik>
	);
}
