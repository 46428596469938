import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import {
	type GroupData,
	type PiiCustomData,
	type QueueData,
	type QueueNumberData,
	db,
	resolveQueueNumberWithPrefix,
} from "@flowby/firebase";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { useCollData } from "../../libs/firebaseHooks";
import CustomData from "../QueueView/CustomData";
import { useToast } from "../shared/Toast";
import UpdateValueAnimation from "../shared/UpdateValueAnimation";

const diffMinutes = (dt2: Date, dt1: Date) => {
	const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
	return Math.abs(Math.round(diff));
};

type PiiDataObject = {
	[key: string]: PiiCustomData & {
		id: string;
	};
};

const getCustomData = (
	piiDataObject: PiiDataObject | undefined,
	queueNumberData: QueueNumberData & { id: string },
	queue: string,
) => {
	if (!piiDataObject) {
		return undefined;
	}
	return piiDataObject[`${queue}|${queueNumberData.id}|customData`]?.data;
};

// TODO: Handle errors
// TODO: Update value animation for data fields blinks for all on all changes to piiDataObject
export default function QueuersView({
	store,
	queue,
	queueData,
	queueNumbersData,
	groupsData,
}: {
	store: string;
	queue: string;
	queueData: QueueData;
	queueNumbersData: (QueueNumberData & { id: string })[];
	groupsData: GroupData[] | undefined;
}) {
	const toast = useToast();
	const [serveLoading, setServeLoading] = useState(false);
	const [page, setPage] = useState(1);
	const itemsPerPage = 30;
	const sortedQueueNumbers = queueNumbersData.sort((a, b) =>
		(a.queueNumber || 10000) > (b.queueNumber || 10000) ? 1 : -1,
	);
	const startQueueNumber = (page - 1) * itemsPerPage;
	const endQueueNumber = page * itemsPerPage;
	const queueNumerDataForPage = useMemo(
		() => sortedQueueNumbers.slice(startQueueNumber, endQueueNumber),
		[sortedQueueNumbers, startQueueNumber, endQueueNumber],
	);
	const piiDataToLookup = useMemo(() => {
		return queueNumerDataForPage
			.filter((qNr) => qNr.customDataSet)
			.map((queueNumber) => {
				return `${queue}|${queueNumber.id}|customData`;
			});
	}, [queueNumerDataForPage]);
	// The firebase method will break if an empty array is supplied, so we add a dummy value if it's empty
	if (piiDataToLookup.length === 0) {
		piiDataToLookup.push("empty");
	}
	const [piiData] = useCollData(
		db.getPiisDataWhereRef(store, "__name__", "in", piiDataToLookup),
		[store, piiDataToLookup],
	);
	const piiDataObject = piiData?.reduce<{
		[key: string]: PiiCustomData & { id: string };
	}>((acc, cur) => {
		acc[cur.id] = cur as PiiCustomData & { id: string };
		// we are sure this can only be custom data since we filter for "|customData"
		return acc;
	}, {});

	const serveCustomer = async (customerID: string) => {
		try {
			setServeLoading(true);
			await db.takeChosenCustomer(store, queue, customerID);
		} catch (e) {
			toast(
				"error",
				"Failed to take customer.",
				"QueuersView/take-chosen-customer-error",
				e,
			);
		}
		setServeLoading(false);
	};

	const [now, setNow] = useState(new Date());

	useEffect(() => {
		const timer = setInterval(() => {
			setNow(new Date());
		}, 10000);
		return () => clearInterval(timer);
	}, []);

	const currentNumberIsManual = queueData.state.currentNumberData?.manual;
	const unorderedQueue = Boolean(queueData?.config?.queueIsUnordered);

	return (
		<Stack align="center" spacing={2} pt={{ base: 2, md: 4 }} px={2}>
			<Stack
				maxW="xl"
				p={2}
				pb={4}
				mb={2}
				alignItems="center"
				justifyContent="center"
				w="100%"
				shadow="md"
				borderWidth="1px"
				borderRadius="0.375rem"
				position="relative"
			>
				<Text
					textTransform="uppercase"
					fontWeight="bold"
					fontSize={{ base: "md", md: "xl" }}
				>
					Now Serving
				</Text>
				<Stack spacing={4} justifyContent="center" alignItems="center">
					<Box
						display="block"
						bgColor={currentNumberIsManual ? "blue.200" : "gray.100"}
						borderRadius="0.375rem"
						px={4}
					>
						<Text fontWeight="bold" fontSize={{ base: "4xl", md: "6xl" }}>
							{resolveQueueNumberWithPrefix(
								queueData.state.currentNumber,
								queueData,
								groupsData,
							)}
						</Text>
					</Box>

					{queueData.customDataFields && queueData.state.currentNumberID && (
						<CustomData
							store={store}
							queueData={queueData}
							queue={queue}
							currentNumberId={queueData.state.currentNumberID}
							fontSize={{ base: "md", md: "xl" }}
						/>
					)}
				</Stack>
			</Stack>
			<Grid
				pt={{ base: 2, md: 4 }}
				maxW="5xl"
				width="100%"
				templateColumns="repeat(5, 1fr)"
			>
				<GridItem
					display="flex"
					colSpan={1}
					justifyContent="center"
					textAlign="center"
				>
					<Heading fontSize={{ base: "xs", md: "lg" }}>Number</Heading>
				</GridItem>
				<GridItem
					display="flex"
					colSpan={1}
					justifyContent="center"
					textAlign="center"
				>
					<Heading fontSize={{ base: "xs", md: "lg" }}>Wait time</Heading>
				</GridItem>
				<GridItem
					display="flex"
					colSpan={unorderedQueue ? 2 : 3}
					justifyContent="center"
					textAlign="center"
				>
					<Heading fontSize={{ base: "xs", md: "lg" }}>Data Input</Heading>
				</GridItem>
				{unorderedQueue && (
					<GridItem
						display="flex"
						colSpan={1}
						justifyContent="center"
						textAlign="center"
					>
						<Heading fontSize={{ base: "xs", md: "lg" }}>Serve now</Heading>
					</GridItem>
				)}
			</Grid>
			{queueNumerDataForPage.length === 0 && (
				<Box pt={{ base: 2, md: 4 }}>
					<Stack maxW="md" textAlign="center" spacing={2} p={6}>
						<Heading size="md">No customers in queue</Heading>
						<Text fontSize="lg">
							When customers are in the queue you can view them individually
							here and choose to serve customers who are not first in line.
						</Text>
					</Stack>
				</Box>
			)}
			{
				// Sort by queueNumber, if queueNumber is not set, put it at the end
				queueNumerDataForPage.map((queueNumberData) => {
					const waitTime = queueNumberData.created
						? diffMinutes(queueNumberData.created.toDate(), now)
						: undefined;
					const customData = getCustomData(
						piiDataObject,
						queueNumberData,
						queue,
					);
					return (
						<Grid
							key={queueNumberData.id}
							maxW="5xl"
							width="100%"
							templateColumns="repeat(5, 1fr)"
							p={2}
							shadow="md"
							borderWidth="1px"
							bg="white"
							borderRadius="0.375rem"
							_hover={{ textDecoration: "none" }}
						>
							<GridItem
								display="flex"
								colSpan={1}
								justifyContent="center"
								alignItems="center"
								textAlign="center"
							>
								<Box
									bgColor={queueNumberData.manual ? "blue.200" : "gray.100"}
									borderRadius="0.375rem"
									px={4}
								>
									<Text fontWeight="bold" fontSize={{ base: "md", md: "4xl" }}>
										{resolveQueueNumberWithPrefix(
											queueNumberData.queueNumber,
											queueData,
											groupsData,
										)}
									</Text>
								</Box>
							</GridItem>
							<GridItem
								display="flex"
								colSpan={1}
								justifyContent="center"
								alignItems="center"
								textAlign="center"
							>
								<UpdateValueAnimation animateOnChange={[waitTime]}>
									<Box>
										<Text fontSize={{ base: "md", md: "xl" }}>
											{waitTime !== undefined ? `${waitTime} min` : "-"}
										</Text>
									</Box>
								</UpdateValueAnimation>
							</GridItem>
							<GridItem
								display="flex"
								colSpan={unorderedQueue ? 2 : 3}
								justifyContent="center"
								alignItems="center"
								textAlign="center"
							>
								{customData && piiDataObject && (
									<CustomData
										store={store}
										queueData={queueData}
										queue={queue}
										currentNumberId={queueNumberData.id}
										fontSize={{ base: "md", md: "xl" }}
									/>
								)}
							</GridItem>
							{unorderedQueue && (
								<GridItem
									display="flex"
									colSpan={1}
									justifyContent="center"
									alignItems="center"
									textAlign="center"
								>
									<Button
										ml={4}
										variant="outline"
										size="xs"
										onClick={() => serveCustomer(queueNumberData.id)}
										isLoading={serveLoading}
									>
										Serve
									</Button>
								</GridItem>
							)}
						</Grid>
					);
				})
			}
			<Stack direction="column" spacing={4} pt={4} pb={4}>
				<Stack direction="row" spacing={4} pt={4} pb={4}>
					<Button
						size={{ base: "sm", md: "md" }}
						variant="outline"
						onClick={() => setPage(page - 1)}
						disabled={page === 1}
					>
						{"<"}
					</Button>
					<Flex alignContent={"center"} justifyContent={"center"}>
						{Array.from(
							{ length: Math.ceil(sortedQueueNumbers.length / itemsPerPage) },
							(_, i) => i + 1,
						).map((nr) => {
							if (nr === page) {
								return (
									<Button
										key={nr}
										size={{ base: "sm", md: "md" }}
										variant="solid"
										onClick={() => setPage(nr)}
									>
										{nr}
									</Button>
								);
							}
							if (Math.abs(nr - page) < 3) {
								return (
									<Button
										key={nr}
										size={{ base: "sm", md: "md" }}
										variant="ghost"
										onClick={() => setPage(nr)}
									>
										{nr}
									</Button>
								);
							}
							if (Math.abs(nr - page) === 3) {
								return (
									<Button
										key={nr}
										size={{ base: "sm", md: "md" }}
										variant="ghost"
										onClick={() => setPage(nr)}
									>
										{"..."}
									</Button>
								);
							}
						})}
					</Flex>
					<Button
						size={{ base: "sm", md: "md" }}
						variant="outline"
						onClick={() => setPage(page + 1)}
						disabled={sortedQueueNumbers.length <= page * itemsPerPage}
					>
						{">"}
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
}
