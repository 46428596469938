import { Stack, Text } from "@chakra-ui/react";
import { type GroupData, db } from "@flowby/firebase";
import slugify from "slugify";
import FormikForm from "../../shared/FormikForm";
import { useToast } from "../../shared/Toast";

export default function CreateGroupForm({
	store,
	groupsData,
	onFinish,
}: {
	store: string;
	groupsData: GroupData[];
	onFinish: (group: string) => void;
}) {
	const toast = useToast();

	const onCreateGroup = async (data: { [key: string]: string }) => {
		try {
			if (groupsData.find((group) => group.shortName === data.groupShortName)) {
				toast(
					"error",
					"A group with the id already exists.",
					"CreateGroupForm/create-group-error",
				);
				return;
			}
			if (!data.groupShortName || !data.groupDisplayName) {
				toast(
					"error",
					"Missing group data.",
					"CreateGroupForm/create-group-error",
				);
				return;
			}
			const groupDisplayName = data.groupDisplayName;
			const groupShortName = slugify(data.groupShortName, { lower: true }); // trims starting or ending dashes
			await db.createGroup(store, groupDisplayName, groupShortName);
			toast("success", "Group created.");
			onFinish(groupShortName);
		} catch (e) {
			toast("error", null, "CreateGroupForm/create-group-error", e);
		}
	};

	return (
		<Stack spacing={4}>
			<Text>Fill in the fields below to create your new queue group.</Text>
			<FormikForm
				fields={[
					{
						type: "textInput",
						id: "groupDisplayName",
						label: "Group Name",
						helperText: "This will be the display name of your queue group.",
						validation: (value: string) => {
							if (!value || value.length === 0) {
								return "Group name is required.";
							}
							// This is due to a limitation in the function resolveQueueNumberWithPrefix
							if (groupsData.length > 4) {
								return "You can only have 5 groups. Contact support if you need more.";
							}
							return undefined;
						},
						onChange: (e, props) => {
							props.setFieldValue(
								"groupShortName",
								slugify(e.target.value, { lower: true }),
							);
							props.handleChange(e);
						},
					},
				]}
				submitButtonText="Create Group"
				onSubmit={async (values) => {
					await onCreateGroup(values);
				}}
			/>
		</Stack>
	);
}
