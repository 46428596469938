import { Box, FormHelperText, FormLabel, Stack, Text } from "@chakra-ui/react";
import type { QueriedAnalyticsData, StoreData } from "@flowby/firebase";
import { useState } from "react";
import { useScreenSize } from "../../../libs/native";
import { getTimeZoneDiffFromUTC } from "../../../libs/timezones";
import Loader from "../../shared/Loader";
import { useToast } from "../../shared/Toast";
import { Chart } from "./Chart";
import { DayChart } from "./DayChart";
import { HourChart } from "./HourChart";
import { Stats } from "./Stats";
import {
	aggregateAnalyticsDataOnDates,
	aggregateAnalyticsDataOnHours,
	calculateWaitTimes,
} from "./analyticsCalculations";

export default function AnalyticsDataView({
	analyticsData,
	dates,
	storeData,
}: {
	analyticsData: QueriedAnalyticsData[];
	dates: string[];
	storeData: StoreData;
}) {
	const toast = useToast();
	const [showingToast, setShowingToast] = useState(false);
	const dateAggregatedAnalyticsData = aggregateAnalyticsDataOnDates(
		analyticsData,
		dates,
	);
	const timezoneDiffFromUTC = getTimeZoneDiffFromUTC(storeData.country);
	if (timezoneDiffFromUTC === null && !showingToast) {
		toast(
			"error",
			"Unable to get stores timezone. The hour view might be misleading.",
			"Analytics/get-time-zone-diff-for-store",
		);
		setShowingToast(true);
	}
	const hourAggregatedAnalyticsData = aggregateAnalyticsDataOnHours(
		analyticsData,
		timezoneDiffFromUTC || 0,
	);
	const waitTimes = calculateWaitTimes(analyticsData);

	const screenSize = useScreenSize();
	return (
		<Stack spacing={4} pb={2}>
			{(!dateAggregatedAnalyticsData || !dates || !waitTimes) && <Loader />}
			{screenSize !== "mobile" &&
				analyticsData &&
				dateAggregatedAnalyticsData &&
				hourAggregatedAnalyticsData &&
				dates &&
				waitTimes && (
					<Stack py={0} spacing={8}>
						<Box>
							<FormLabel>Period Overview</FormLabel>
							<FormHelperText>
								This chart shows the overview for all of the dates during the
								selected period.
							</FormHelperText>
							<Stats
								aggregatedAnalyticsData={dateAggregatedAnalyticsData}
								waitTimes={waitTimes}
							/>
							<Chart
								dates={dates}
								aggregatedAnalyticsData={dateAggregatedAnalyticsData}
								waitTimes={waitTimes}
							/>
						</Box>
						<Box>
							<FormLabel>Weekday View</FormLabel>
							<FormHelperText>
								This chart shows the queue data aggregated by weekday for the
								selected period. Use it to see which days your store has the
								most traffic.
							</FormHelperText>
							<DayChart
								dates={dates}
								aggregatedAnalyticsData={dateAggregatedAnalyticsData}
								waitTimes={waitTimes}
							/>
						</Box>
						<Box>
							<FormLabel>Hour View</FormLabel>
							<FormHelperText>
								This chart shows the queue data aggregated by hour during the
								day for the selected period. Use it to see which hours your
								store has the most traffic.
							</FormHelperText>
							<HourChart
								hourAggregatedAnalyticsData={hourAggregatedAnalyticsData}
							/>
						</Box>
					</Stack>
				)}
			{screenSize === "mobile" &&
				analyticsData &&
				dateAggregatedAnalyticsData &&
				dates &&
				waitTimes && (
					<Stack py={4} spacing={8}>
						<Stats
							aggregatedAnalyticsData={dateAggregatedAnalyticsData}
							waitTimes={waitTimes}
						/>
						<Text fontSize="xl">
							Login to the flowby website on a computer to see a more detailed
							analytics view.
						</Text>
					</Stack>
				)}
		</Stack>
	);
}
