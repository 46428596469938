import type { QueriedAnalyticsData } from "@flowby/firebase";
import { filterUndefined } from "./utils";

const calculateOutlierValues = (someArray: number[]) => {
	const values = someArray.sort((a: number, b: number) => a - b);

	/* Then find a generous IQR. This is generous because if (values.length / 4)
	 * is not an int, then really you should average the two elements on either
	 * side to find q1.
	 */
	const q1 = values[Math.floor(values.length / 4)];
	// Likewise for q3.
	const q3 = values[Math.ceil(values.length * (3 / 4))];
	if (q1 === undefined || q3 === undefined) {
		return undefined;
	}
	const iqr = q3 - q1;

	// Then find min and max values
	const maxValue = q3 + iqr * 1.5;
	const minValue = q1 - iqr * 1.5;
	return { maxValue, minValue };
};

export function calculateWaitTimes(analyticsData: QueriedAnalyticsData[]) {
	const deletedDataPoints = analyticsData.filter(
		(dataPoint) => dataPoint.action === "delete",
	);
	const createdDataPointsObject = analyticsData.reduce(
		(acc, curr) => {
			if (curr.action === "create") {
				acc[curr.queuer_id] = curr;
			}
			return acc;
		},
		{} as Record<string, QueriedAnalyticsData>,
	);
	const timeArray: number[] = [];
	const times = filterUndefined(
		deletedDataPoints.map((deletedDataPoint) => {
			const deletedTime = new Date(deletedDataPoint.when.value);
			const created = createdDataPointsObject[deletedDataPoint.queuer_id];
			if (created) {
				const createdTime = new Date(created.when.value);
				const waitTime = deletedTime.valueOf() - createdTime.valueOf();
				timeArray.push(waitTime);
				return {
					queuerId: deletedDataPoint.queuer_id,
					date: created.date.value,
					dateTime: createdTime,
					wait: waitTime,
				};
			}
			return undefined;
		}),
	);
	const outlierValues = calculateOutlierValues(timeArray);
	const timesWithoutOutliers = outlierValues
		? times.filter((time) => time.wait < outlierValues?.maxValue)
		: times;
	return timesWithoutOutliers;
}

export const aggregateAnalyticsDataOnDates = (
	analyticsData: QueriedAnalyticsData[],
	dates: string[],
) => {
	const aggregatedData = dates.map(() => ({
		totalCount: 0,
		manualCount: 0,
		kioskCount: 0,
		smsSentCount: 0,
		dataInputCount: 0,
	}));

	analyticsData.map((dataPoint) => {
		const dateIndex = dates.indexOf(dataPoint.date.value);
		const aggregatedDataToUpdate = aggregatedData[dateIndex];
		if (aggregatedDataToUpdate) {
			if (dataPoint.action === "create") {
				aggregatedDataToUpdate.totalCount += 1;
			}
			if (dataPoint.action === "create" && dataPoint.manual) {
				aggregatedDataToUpdate.manualCount += 1;
			}
			if (dataPoint.action === "create" && dataPoint.kiosk) {
				aggregatedDataToUpdate.kioskCount += 1;
			}
			if (dataPoint.action === "sms_sent") {
				aggregatedDataToUpdate.smsSentCount += 1;
			}
			if (dataPoint.action === "input_data") {
				aggregatedDataToUpdate.dataInputCount += 1;
			}
		}
	});
	return aggregatedData;
};

export const hours = [
	"00:00",
	"01:00",
	"02:00",
	"03:00",
	"04:00",
	"05:00",
	"06:00",
	"07:00",
	"08:00",
	"09:00",
	"10:00",
	"11:00",
	"12:00",
	"13:00",
	"14:00",
	"15:00",
	"16:00",
	"17:00",
	"18:00",
	"19:00",
	"20:00",
	"21:00",
	"22:00",
	"23:00",
];

export const aggregateAnalyticsDataOnHours = (
	analyticsData: QueriedAnalyticsData[],
	timezoneDiffFromUTC: number,
) => {
	const getHour = (dateString: string) => {
		const date = new Date(dateString);
		const hour = date.getUTCHours() - timezoneDiffFromUTC;
		return hours[hour];
	};
	const aggregatedData = hours.map(() => ({
		totalCount: 0,
		manualCount: 0,
		kioskCount: 0,
		smsSentCount: 0,
		dataInputCount: 0,
	}));
	analyticsData.map((dataPoint) => {
		const currHour = getHour(dataPoint.when.value);
		const hourIndex = hours.indexOf(currHour || "25:00");
		const aggregatedDataToUpdate = aggregatedData[hourIndex];
		if (aggregatedDataToUpdate) {
			if (dataPoint.action === "create") {
				aggregatedDataToUpdate.totalCount += 1;
			}
			if (dataPoint.action === "create" && dataPoint.manual) {
				aggregatedDataToUpdate.manualCount += 1;
			}
			if (dataPoint.action === "create" && dataPoint.kiosk) {
				aggregatedDataToUpdate.kioskCount += 1;
			}
			if (dataPoint.action === "sms_sent") {
				aggregatedDataToUpdate.smsSentCount += 1;
			}
			if (dataPoint.action === "input_data") {
				aggregatedDataToUpdate.dataInputCount += 1;
			}
		}
	});
	return aggregatedData;
};
