import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { type GroupData, type QueueData, db } from "@flowby/firebase";
import { Link as RouterLink } from "react-router-dom";
import { useCollData } from "../../libs/firebaseHooks";
import useStore from "../../libs/zustand";
import UpdateValueAnimation from "../shared/UpdateValueAnimation";
import WaitTime from "./WaitTime";

function truncate(str: string, n: number) {
	return str.length > n ? `${str.slice(0, n - 1)}...` : str;
}

const QueuesInGroupItem = ({
	store,
	queue,
	queueData,
}: {
	store: string;
	queue: string;
	queueData: QueueData;
}) => {
	const [queueNumbersData] = useCollData(
		db.getQueueNumbersRef(store, queueData.shortName),
		[store, queue, queueData.shortName],
	);
	const sortedQueueNumbersData = queueNumbersData?.sort((a, b) =>
		(a.queueNumber || 10000) > (b.queueNumber || 10000) ? 1 : -1,
	);
	// The weird links are to make the whole row clickable
	return queue === queueData.shortName ? (
		<Tr>
			<Td py={0} pl={1}>
				<Box display="inline-block" my={2}>
					<Heading
						color="green.700"
						fontSize="xs"
						px={0}
						display="inline"
						textOverflow="ellipsis"
					>
						{truncate(queueData.displayName, 17).toUpperCase()}
					</Heading>
				</Box>
			</Td>
			<Td p={0} textAlign="center">
				<Box my={2} display="inline-block">
					<WaitTime queueNumbersData={sortedQueueNumbersData} />
				</Box>
			</Td>
			<Td p={0} textAlign="center">
				<Box my={2} display="inline-block">
					<UpdateValueAnimation animateOnChange={[queueData.state.count]}>
						<Text px={2}>{queueData.state.count}</Text>
					</UpdateValueAnimation>
				</Box>
			</Td>
		</Tr>
	) : (
		<Tr>
			<Td p={0} pl={1}>
				<RouterLink
					to={`/${store}/q/${queueData.shortName}`}
					style={{ display: "block" }}
				>
					<Box display="inline-block" my={2}>
						<Button
							variant="ghost"
							size="xs"
							fontSize="xs"
							px={0}
							display="inline"
							as={RouterLink}
							to={`/${store}/q/${queueData.shortName}`}
							textOverflow="ellipsis"
						>
							{truncate(queueData.displayName, 17)}
						</Button>
					</Box>
				</RouterLink>
			</Td>
			<Td p={0} textAlign="center">
				<RouterLink
					to={`/${store}/q/${queueData.shortName}`}
					style={{ display: "block" }}
				>
					<Box my={2} display="inline-block">
						<WaitTime queueNumbersData={sortedQueueNumbersData} />
					</Box>
				</RouterLink>
			</Td>
			<Td p={0} textAlign="center">
				<RouterLink
					to={`/${store}/q/${queueData.shortName}`}
					style={{ display: "block" }}
				>
					<Box my={2} display="inline-block">
						<UpdateValueAnimation animateOnChange={[queueData.state.count]}>
							<Text px={2}>{queueData.state.count}</Text>
						</UpdateValueAnimation>
					</Box>
				</RouterLink>
			</Td>
		</Tr>
	);
};

export default function QueuesInSameGroup({
	store,
	queue,
	groupsData,
}: {
	store: string;
	queue: string;
	groupsData: GroupData[] | undefined;
}) {
	const queuesInSameGroup = groupsData?.reduce<string[]>((acc, curr) => {
		return curr.queues.includes(queue) ? acc.concat(curr.queues) : acc;
	}, []);
	const [queuesData] = useCollData(db.getQueuesRef(store), [store]);
	// used to save the user choice of if they want to have open or closed accordion
	const openQueuesInGroupAccordion = useStore(
		(state) => state.openQueuesInGroupAccordion,
	);
	const setOpenQueuesInGroupAccordion = useStore(
		(state) => state.setOpenQueuesInGroupAccordion,
	);

	if (!queuesInSameGroup || queuesInSameGroup.length === 0) {
		return null;
	}

	return (
		<Accordion
			allowToggle
			defaultIndex={openQueuesInGroupAccordion}
			onChange={(props) => {
				// weird workaround due ot ExpandedIndex being either a number or an array of numbers
				if (Number.isInteger(props)) {
					setOpenQueuesInGroupAccordion(props as number);
				}
			}}
		>
			<AccordionItem style={{ border: "none" }}>
				<AccordionButton
					variant="ghost"
					size="sm"
					fontSize="sm"
					as={Button}
					style={{ boxShadow: "none" }}
				>
					Show queues in group
					<AccordionIcon />
				</AccordionButton>
				<AccordionPanel p={0}>
					<Table size="sm" variant="simple">
						<Thead>
							<Tr>
								<Th px={2}>Queue</Th>
								<Th px={2} textAlign="center">
									Wait time
								</Th>
								<Th px={2} textAlign="center">
									Queuers
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{queuesData?.map((q) => {
								if (q.state.status !== "closed") {
									return (
										queuesInSameGroup.includes(q.shortName) && (
											<QueuesInGroupItem
												key={q.shortName}
												store={store}
												queue={queue}
												queueData={q}
											/>
										)
									);
								}
							})}
						</Tbody>
					</Table>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
}
