import {
	AlignmentPosition,
	type CommandsArray,
	CutPaperAction,
	Encoding,
	FontStyleType,
	StarPRNT,
	type Printer as StarPrinter,
} from "@awesome-cordova-plugins/star-prnt";

// Star emulator list:
// https://www.star-m.jp/products/s_print/sdk/starprnt_sdk/manual/uwp_csharp/en/getting_start.html
// https://github.com/auctifera-josed/starprnt
// https://github.com/danielsogl/awesome-cordova-plugins/blob/master/src/%40awesome-cordova-plugins/plugins/star-prnt/index.ts

export type Printer = StarPrinter;

const getPrinterCommands = ({
	footer,
	queueNumber,
	queueNumberId,
	store,
	queue,
	queueName,
}: {
	footer: string;
	queueNumber: string;
	queueNumberId: string;
	store: string;
	queue: string;
	queueName: string;
}) =>
	[
		{
			appendCodePage: StarPRNT.CodePageType.UTF8,
		},
		{
			appendEncoding: Encoding.UTF8,
		},
		{
			appendAlignment: AlignmentPosition.Center,
		},
		{
			appendFontStyle: FontStyleType.A,
		},
		{
			appendEmphasis: `${queueName}\n`,
		},
		{
			appendLineFeed: 1,
		},
		{
			appendMultiple: queueNumber,
			width: 4,
			height: 4,
		},
		{
			appendLineFeed: 1,
		},
		{
			appendQrCode: `${process.env.REACT_APP_ENV === "dev" ? "https://dev.go.flowby.io" : "https://go.flowby.io"}/${store}/q/${queue}/c/${queueNumberId}`,
			QrCodeModel: "No2",
			QrCodeLevel: "L",
			cell: 6,
		},
		{
			appendLineFeed: 1,
		},
		{
			append: JSON.parse(JSON.stringify(`${footer}`)),
		},
		{
			appendLineFeed: 1,
		},
		{
			appendCutPaper: CutPaperAction.PartialCutWithFeed,
		},
	] as CommandsArray;

export const findPrinter = async () => {
	const devices = await StarPRNT.portDiscovery("All");
	return devices;
};

export const checkStatus = async (printerPort: string) => {
	const status = await StarPRNT.checkStatus(printerPort, "StarPRNT");
	return status;
};

export const print = async ({
	printerPort,
	footer,
	queueNumber,
	queueNumberId,
	store,
	queue,
	queueName,
}: {
	printerPort: string;
	footer: string;
	queueNumber: string;
	queueNumberId: string;
	store: string;
	queue: string;
	queueName: string;
}) => {
	return StarPRNT.print(
		printerPort,
		"StarPRNT",
		getPrinterCommands({
			footer,
			queueNumber,
			queueNumberId,
			store,
			queue,
			queueName,
		}),
	);
};
