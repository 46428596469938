import { getApp, getApps, initializeApp } from "firebase/app";
import { browserLocalPersistence, initializeAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfigs: {
	[key: string]: { [key: string]: { [key: string]: string } };
} = {
	store: {
		dev: {
			apiKey: "AIzaSyC39aObTAGD40SgdPewiKwfgr64UB_YvbU",
			authDomain: "flowby-io-dev.firebaseapp.com",
			projectId: "flowby-io-dev",
			storageBucket: "flowby-io-dev.appspot.com",
			messagingSenderId: "268001400528",
			appId: "1:268001400528:web:26ef5052a0f41e30fbb0d5",
		},
		prod: {
			apiKey: "AIzaSyC5aSjsV8RnW5y8GwDde6pGyNWKHU4vJ50",
			authDomain: "flowby-io.firebaseapp.com",
			projectId: "flowby-io",
			storageBucket: "flowby-io.appspot.com",
			messagingSenderId: "772562180384",
			appId: "1:772562180384:web:c0a01ee1262282cf399f45",
		},
	},
	customer: {
		dev: {
			apiKey: "AIzaSyC39aObTAGD40SgdPewiKwfgr64UB_YvbU",
			authDomain: "flowby-io-dev.firebaseapp.com",
			projectId: "flowby-io-dev",
			storageBucket: "flowby-io-dev.appspot.com",
			messagingSenderId: "268001400528",
			appId: "1:268001400528:web:a969d2bde712968ffbb0d5",
		},
		prod: {
			apiKey: "AIzaSyC5aSjsV8RnW5y8GwDde6pGyNWKHU4vJ50",
			authDomain: "flowby-io.firebaseapp.com",
			projectId: "flowby-io",
			storageBucket: "flowby-io.appspot.com",
			messagingSenderId: "772562180384",
			appId: "1:772562180384:web:039403aca9d699d4399f45",
		},
	},
};

const APP = process.env.REACT_APP_APP
	? process.env.REACT_APP_APP
	: process.env.NEXT_PUBLIC_APP;
const ENV = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NEXT_PUBLIC_ENV;

if (!APP) {
	throw new Error("App not set!");
}
if (!ENV) {
	throw new Error("Env not set!");
}

export const fbApp =
	getApps().length === 0 ? initializeApp(firebaseConfigs[APP][ENV]) : getApp();
export const auth = initializeAuth(fbApp, {
	persistence: browserLocalPersistence,
});
export const firestore = getFirestore(fbApp);
export const storage = getStorage(fbApp);
export const functions = getFunctions(fbApp, "europe-west1");
