import {
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	Text,
} from "@chakra-ui/react";
import { auth } from "@flowby/firebase";
import { useState } from "react";
import { IoEnterOutline } from "react-icons/io5";
/* eslint-disable indent */
import { useNavigate } from "react-router-dom";
import Header from "../components/shared/Header";
import { useToast } from "../components/shared/Toast";

export default function ForgotPasswordPage() {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const navigate = useNavigate();

	const forgotPassword = async (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();

		const actionCodeSettings = {
			url: `${process.env.REACT_APP_ENV === "dev" ? "https://dev.admin.flowby.io" : "https://admin.flowby.io"}/login`,
		};
		setLoading(true);
		try {
			await auth.sendForgotPasswordEmail(email, actionCodeSettings);
			setLoading(false);
			toast("success", "A reset link has been sent to your email inbox.");
		} catch (e: any) {
			setLoading(false);
			if (e.code) {
				switch (e.code) {
					case "auth/user-not-found": {
						toast("error", "User not found.", "ForgotPassword/user-not-found");
						break;
					}
					case "auth/invalid-email": {
						toast(
							"error",
							"Invalid email address.",
							"ForgotPassword/invalid-email",
						);
						break;
					}
					default: {
						toast("error", null, "ForgotPassword/unknown-error", e);
						break;
					}
				}
			} else {
				toast("error", null, "ForgotPassword/unknown-error", e);
			}
		}
	};

	const isInvalid = email === "";

	return (
		<Flex minH={"90vh"} flexDirection="column">
			<Header
				leftComponent={null}
				rightComponent={{
					text: "Login",
					icon: <IoEnterOutline size={24} />,
					onClick: () => {
						navigate("/login");
					},
				}}
			/>
			<Container>
				<Stack
					spacing={8}
					mx={"auto"}
					maxW={"lg"}
					py={{ base: 8, md: 12 }}
					px={{ base: 0, md: 6 }}
				>
					<Stack align={"center"}>
						<Heading fontSize={"4xl"}>Forgot your password?</Heading>
						<Text fontSize="lg">
							Input your email below and we'll send you a link to reset it.
						</Text>
					</Stack>
					<Box
						rounded={"lg"}
						p={8}
						borderWidth="1px"
						bg="white"
						shadow="md"
						borderColor="gray.200"
						borderRadius="0.375rem"
					>
						<Stack spacing={4}>
							<FormControl id="email">
								<FormLabel>Email address</FormLabel>
								<Input
									name="email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>
							<Stack spacing={5}>
								<Button
									isLoading={loading}
									disabled={isInvalid}
									onClick={forgotPassword}
								>
									Reset password
								</Button>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Container>
		</Flex>
	);
}
