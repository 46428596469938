import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	type UseModalProps,
} from "@chakra-ui/react";
import slugify from "slugify";

export default function ModalContainer({
	isOpen,
	onClose,
	header,
	content,
	footer,
	alwaysFull,
	closeOnOverlayClick = true,
	closeButton = true,
}: {
	isOpen: UseModalProps["isOpen"];
	onClose: UseModalProps["onClose"];
	header: string;
	content: JSX.Element;
	footer?: JSX.Element;
	alwaysFull?: boolean;
	closeOnOverlayClick?: boolean;
	closeButton?: boolean;
}) {
	return (
		<Modal
			closeOnOverlayClick={closeOnOverlayClick}
			scrollBehavior="inside"
			onClose={onClose}
			isCentered
			size={alwaysFull ? "full" : "xl"}
			isOpen={isOpen}
			preserveScrollBarGap
		>
			<ModalOverlay />
			<ModalContent mt={0}>
				<ModalHeader pr={12}>{header}</ModalHeader>
				{closeButton ? (
					<ModalCloseButton
						data-testid={`${slugify(header, { lower: true })}-close-button`}
					/>
				) : null}
				<ModalBody>{content}</ModalBody>
				<ModalFooter pb={0} pt={2}>
					{footer}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
