import {
	type DataField,
	type StoreData,
	type SupportedLangs,
	db,
} from "@flowby/firebase";
import { translations } from "@flowby/general";
import FormikForm from "../../shared/FormikForm";
import { useToast } from "../../shared/Toast";

export default function CreateDataField({
	store,
	queue,
	storeData,
	onFinish,
}: {
	store: string;
	queue: string;
	storeData: StoreData;
	onFinish: () => void;
}) {
	const toast = useToast();

	const createDataField = async (data: { [key: string]: any }) => {
		const id = new Date().getTime().toString();
		const transformedData: DataField = storeData.supportedLangs.reduce(
			(acc, lang) => {
				const dataFieldName = data[`${lang}-dataFieldName`];
				const dataFieldDescription = data[`${lang}-dataFieldDescription`];
				if (!dataFieldName || !dataFieldDescription) {
					throw new Error("Missing data field name or description");
				}
				acc[lang] = {
					name: dataFieldName,
					description: dataFieldDescription,
					placeholder: data[`${lang}-dataFieldPlaceholder`],
				};
				return acc;
			},
			{} as DataField,
		);

		try {
			await db.updateQueue(store, queue, {
				[`customDataFields.${id}`]: {
					...transformedData,
					required: data.dataFieldRequiredLabel,
				},
			});
			toast("success", "Data field added.");
		} catch (e) {
			toast("error", null, "CreateEditDataField/error-creating-data-field", e);
		}
		onFinish();
	};

	const dataFieldNameInputs = storeData.supportedLangs.map((lang) => {
		return {
			type: "textInput",
			id: `${lang}-dataFieldName`,
			helperText: translations[lang as SupportedLangs].name,
			formHelperTextStyle: { margin: 0 },
			placeholder:
				lang === "sv" ? "Exempel: Ordernummer" : "Example: Order Number",
			validation: (value: string) => {
				if (!value || value.length === 0) {
					return "You need to add a data field name for all supported languages.";
				}
				return undefined;
			},
		};
	});
	const dataFieldDescription = storeData.supportedLangs.map((lang) => {
		return {
			type: "textArea",
			id: `${lang}-dataFieldDescription`,
			helperText: translations[lang as SupportedLangs].name,
			placeholder:
				lang === "sv"
					? "Exempel: Skriv in ditt ordernummer, vilket du hittar i ditt bekräftelsemail."
					: "Example: Input your order number, which you can find in your confirmation e-mail.",
			formHelperTextStyle: { margin: 0 },
			validation: (value: string) => {
				if (!value || value.length === 0) {
					return "You need to add a data field description for all supported languages.";
				}
				return undefined;
			},
		};
	});
	const dataFieldPlaceHolder = storeData.supportedLangs.map((lang) => {
		return {
			type: "textInput",
			id: `${lang}-dataFieldPlaceholder`,
			helperText: translations[lang as SupportedLangs].name,
			placeholder: lang === "sv" ? "Exempel: A12345" : "Example: A12345",
			formHelperTextStyle: { margin: 0 },
			validation: (value: string) => {
				if (!value || value.length === 0) {
					return "You need to add a data field placeholder for all supported languages.";
				}
				return undefined;
			},
		};
	});

	return (
		<FormikForm
			fields={[
				{
					type: "label",
					id: "dataFieldNameLabel",
					label: "Name",
					helperText:
						"Choose a name for this field which describes what kind of data you want the customer to input.",
				},
				...dataFieldNameInputs,
				{
					type: "label",
					id: "dataFieldDescriptionLabel",
					label: "Description",
					helperText:
						"Write a description which further explains the data and where the customer can find it.",
				},
				...dataFieldDescription,
				{
					type: "label",
					id: "dataFieldPlaceholderLabel",
					label: "Example Input",
					helperText:
						"Write an example of the data which clarifies the format.",
				},
				...dataFieldPlaceHolder,
				{
					type: "switch",
					id: "dataFieldRequiredLabel",
					helperText: "Should the field be required?",
					formHelperTextStyle: { display: "inline-block", marginRight: 4 },
					label: "Required",
				},
			]}
			submitButtonText="Add"
			onSubmit={async (values) => {
				await createDataField(values);
			}}
		/>
	);
}
