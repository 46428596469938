import {
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	Heading,
	Select,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react";
import type { GroupData, QueueData } from "@flowby/firebase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { type Printer, findPrinter } from "../../libs/printer";
import { useToast } from "../shared/Toast";

export default function KioskGuide({
	store,
	groupsData,
	queuesData,
}: {
	store: string;
	groupsData: GroupData[];
	queuesData: QueueData[];
}) {
	const navigate = useNavigate();
	const toast = useToast();
	const [kioskUrl, setKioskUrl] = useState<string | undefined>(undefined);
	const [checkedPrinter, setCheckedPrinter] = useState(false);
	const [lookingForPrinters, setLookingForPrinters] = useState(false);
	const [printers, setPrinters] = useState<undefined | Printer[]>(undefined);
	const [printerConnected, setPrinterConnected] = useState<
		undefined | Printer["portName"]
	>(undefined);

	return (
		<Container id="kiosk-guide" mb={2}>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={{ base: 4, md: 8 }}>
				<Text fontSize="lg">
					Allow your customers to take a queue number from a tablet. By checking
					the “Use printer” below, you can print the queue numbers.
				</Text>
				<Text fontSize="lg">
					To enter the kiosk, select a queue or group below. Exit by restarting
					the Flowby app.
				</Text>
				<Stack spacing={4} alignItems="center">
					<Select
						placeholder="Select queue or group"
						onChange={(e) => {
							const queueOrGroup = e.target.value.split(":")[0];
							const id = e.target.value.split(":")[1];
							setKioskUrl(`/kiosk/${store}/${queueOrGroup}/${id}`);
							setPrinterConnected(undefined);
						}}
						name="selectKioskQueueOrGroup"
					>
						{queuesData.map((queue) => (
							<option key={queue.shortName} value={`q:${queue.shortName}`}>
								{queue.displayName}
							</option>
						))}
						{groupsData.map((group) => (
							<option key={group.shortName} value={`g:${group.shortName}`}>
								{group.displayName}
							</option>
						))}
					</Select>
					<Checkbox
						disabled={!kioskUrl}
						onChange={(e) => {
							setCheckedPrinter(e.target.checked);
						}}
						defaultChecked={false}
					>
						Use printer
					</Checkbox>
					{checkedPrinter && (
						<Stack
							p={5}
							shadow="md"
							borderWidth="1px"
							bg="white"
							borderRadius="0.375rem"
						>
							<Text>
								Connect your Star Micronics printer using a USB cable and click
								on the button below to search for printers.
							</Text>
							<Button
								isLoading={lookingForPrinters}
								size="sm"
								onClick={async () => {
									setPrinters(undefined);
									setLookingForPrinters(true);
									const printers = await findPrinter();
									setPrinters(printers);
									setLookingForPrinters(false);
								}}
							>
								Search
							</Button>
							<Divider />
							<Stack divider={<StackDivider />} spacing="4">
								{printers &&
									printers.length > 0 &&
									printers.map((printer) => {
										const isConnected = printer.portName === printerConnected;
										return (
											<Stack
												key={printer.portName}
												direction="row"
												justifyContent="space-between"
												alignItems="center"
											>
												<Box>
													<Heading size="xs" textTransform="uppercase">
														Star Micronics {printer.modelName}
													</Heading>
													<Text pt="2" fontSize="sm">
														{printer.portName}
													</Text>
												</Box>
												<Button
													isDisabled={isConnected}
													size="sm"
													justifySelf="right"
													onClick={async () => {
														if (!printer.portName) {
															setPrinterConnected(undefined);
															toast(
																"error",
																"Printer port name not found.",
																"KioskGuide",
															);
														} else {
															if (kioskUrl) {
																setPrinterConnected(printer.portName);
																toast("success", "Printer connected.");
																setKioskUrl(
																	`${kioskUrl.split("?printer")[0]}?printer=${printer.portName}`,
																);
															}
														}
													}}
												>
													{isConnected ? "Connected" : "Connect"}
												</Button>
											</Stack>
										);
									})}
							</Stack>
							{printers && printers.length === 0 && (
								<Text>No printers found.</Text>
							)}
						</Stack>
					)}
				</Stack>
				<Button
					size="md"
					isDisabled={!kioskUrl}
					onClick={() => {
						if (kioskUrl) {
							navigate(kioskUrl);
						}
					}}
				>
					Enter Kiosk
				</Button>
			</Stack>
		</Container>
	);
}
