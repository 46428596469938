import {
	type CollectionReference,
	type DocumentData,
	type DocumentReference,
	type QueryDocumentSnapshot,
	type SnapshotOptions,
	type UpdateData,
	type WhereFilterOp,
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	increment,
	limit,
	onSnapshot,
	orderBy,
	query,
	runTransaction,
	serverTimestamp,
	setDoc,
	updateDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { uploadBytes } from "firebase/storage";
import { ref } from "firebase/storage";
import type {
	GroupData,
	PiiCustomData,
	PiiPhoneData,
	QueriedAnalyticsData,
	QueueData,
	QueueNumberData,
	QueueState,
	QueueTranslations,
	ReviewData,
	StoreData,
	SupportedLangs,
	User,
} from "../types";
import { firestore, functions, storage } from "./config";

export const onChange = onSnapshot;

export const generateId = () => collection(firestore, "dummy").id;
const addIdConverter = <T>() => ({
	toFirestore(data: any) {
		return {
			data,
		};
	},
	fromFirestore: (
		snapshot: QueryDocumentSnapshot<DocumentData>,
		options: SnapshotOptions,
	) => {
		const data = snapshot.data(options);
		return {
			id: snapshot.id,
			...data,
		} as T & { id: string };
	},
});

export const getStoreRef = (store: string) =>
	doc(firestore, "stores", store) as DocumentReference<StoreData>;
export const getStoresRef = () =>
	collection(firestore, "stores").withConverter(addIdConverter<StoreData>());

export const getQueueRef = (store: string, queue: string) =>
	doc(
		firestore,
		"stores",
		store,
		"queues",
		queue,
	) as DocumentReference<QueueData>;
export const getQueuesRef = (store: string) =>
	collection(
		firestore,
		"stores",
		store,
		"queues",
	) as CollectionReference<QueueData>;

export const getGroupRef = (store: string, group: string) =>
	doc(
		firestore,
		"stores",
		store,
		"groups",
		group,
	) as DocumentReference<GroupData>;
export const getGroupsRef = (store: string) =>
	collection(
		firestore,
		"stores",
		store,
		"groups",
	) as CollectionReference<GroupData>;

export const getQueueNumberRef = (
	store: string,
	queue: string,
	queueNumberId: string,
) =>
	doc(
		firestore,
		"stores",
		store,
		"queues",
		queue,
		"queue",
		queueNumberId,
	) as DocumentReference<QueueNumberData>;
export const getQueueNumbersRef = (store: string, queue: string) =>
	collection(
		firestore,
		"stores",
		store,
		"queues",
		queue,
		"queue",
	).withConverter(addIdConverter<QueueNumberData>());

export const getPiiRef = (
	store: string,
	queue: string,
	queueNumberId: string,
) =>
	doc(
		firestore,
		"stores",
		store,
		"pii",
		`${queue}|${queueNumberId}`,
	) as DocumentReference<PiiPhoneData>;
export const getPiisRef = (store: string) =>
	collection(firestore, "stores", store, "pii") as CollectionReference<
		PiiPhoneData | PiiCustomData
	>;
export const getPiiCustomDataRef = (
	store: string,
	queue: string,
	queueNumberId: string,
) =>
	doc(
		firestore,
		"stores",
		store,
		"pii",
		`${queue}|${queueNumberId}|customData`,
	) as DocumentReference<PiiCustomData>;
export const getPiisDataWhereRef = (
	store: string,
	field: string,
	operator: WhereFilterOp,
	value: any,
) => {
	return query(
		getPiisRef(store).withConverter(
			addIdConverter<PiiPhoneData | PiiCustomData>(),
		),
		where(field, operator, value),
	);
};

export const createQueueNumber = async (
	store: string,
	queue: string,
	manual: boolean,
	kiosk: boolean,
) => {
	const tRes = await runTransaction(firestore, async (t) => {
		const queueDoc = await t.get(getQueueRef(store, queue));
		const queueData = queueDoc.data() as QueueData;
		if (!queueData) {
			throw new Error(
				`Queue data not found. Store: ${store}. Queue: ${queue}.`,
			);
		}
		const newQueueNumber = queueData.state.nextNumber;
		const queueNumberRef = doc(collection(getQueueRef(store, queue), "queue"));
		t.set(queueNumberRef, {
			queueNumber: newQueueNumber,
			manual,
			created: serverTimestamp(),
			kiosk,
		}).update(getQueueRef(store, queue), {
			"state.nextNumber": increment(1),
			"state.count": increment(1),
		});
		return { id: queueNumberRef.id, queueNumber: newQueueNumber };
	});
	return { sucess: true, ...tRes };
};

export const convertManualQueueNumber = async (
	store: string,
	queue: string,
	queueNumberId: string,
) => await updateQueueNumber(store, queue, queueNumberId, { manual: false });

// TODO: This should throw an error and be handled in the UI with try catch
export const moveBackInQueue = async (
	store: string,
	queue: string,
	queueNumberId: string,
) => {
	const moveBackInQueue = httpsCallable(functions, "moveBackInQueue");
	try {
		const response = await moveBackInQueue({ store, queue, queueNumberId });
		return response.data as {
			success: true;
			oldNumber: number;
			newNumber: number;
		};
	} catch (e) {
		console.error("Error when creating queue number", e);
		return { success: false } as { success: false };
	}
};

export const leaveQueue = async (
	store: string,
	queue: string,
	queueNumberId: string,
) => {
	const tRes = await runTransaction(firestore, async (t) => {
		const queueDoc = await t.get(getQueueRef(store, queue));
		const queueData = queueDoc.data();
		const queueNumberDoc = await t.get(
			getQueueNumberRef(store, queue, queueNumberId),
		);
		const queueNumberData = queueNumberDoc.data();
		if (!queueData || !queueNumberData) {
			throw new Error(
				`Queue data not found. Store: ${store}. Queue: ${queue}.`,
			);
		}
		t.delete(getQueueNumberRef(store, queue, queueNumberId)).update(
			getQueueRef(store, queue),
			{
				"state.count": increment(-1),
			},
		);

		return { id: queueNumberId };
	});
	return tRes;
};

// We store phone number in a pii collection in the store to not allow public access to the data
export const storePhoneNumber = (
	queueNumberId: string,
	store: string,
	queue: string,
	phoneNumber: string,
	language: SupportedLangs, // language is used for determining translation for the sms notification
) => {
	const batch = writeBatch(firestore);
	const piiPhoneData: PiiPhoneData = {
		queue,
		phoneNumber,
		language,
		created: serverTimestamp(),
	};
	batch.set(getPiiRef(store, queue, queueNumberId), piiPhoneData, {
		merge: true,
	});
	batch.set(
		getQueueNumberRef(store, queue, queueNumberId),
		{ phoneSet: true },
		{ merge: true },
	);
	batch.commit();
};

// A bit hacky, the meaning of phoneSet is:
// true: phone number is set
// false: phone number is not set and the user has been asked
// null: phone is not set and the user has not been asked
export const setPhoneAsked = async (
	store: string,
	queue: string,
	queueNumberId: string,
) => {
	await updateDoc(getQueueNumberRef(store, queue, queueNumberId), {
		phoneSet: false,
	});
};

// We store custom data input in a pii collection in the store to not allow public access to the data
// TODO: Handle error case
export const storeCustomDataInput = (
	queueNumberId: string,
	store: string,
	queue: string,
	data: {
		[key: string]: string;
	},
) => {
	const batch = writeBatch(firestore);
	const piiCustomData: PiiCustomData = {
		data,
		created: serverTimestamp(),
	};
	batch.set(getPiiCustomDataRef(store, queue, queueNumberId), piiCustomData, {
		merge: true,
	});
	batch.set(
		getQueueNumberRef(store, queue, queueNumberId),
		{ customDataSet: true },
		{ merge: true },
	);
	batch.commit();
};

// TODO: Handle error case
export const removeUserPhoneNumber = (
	queueNumberId: string,
	store: string,
	queue: string,
) => {
	const batch = writeBatch(firestore);
	batch.delete(getPiiRef(store, queue, queueNumberId));
	batch.set(
		getQueueNumberRef(store, queue, queueNumberId),
		{ phoneSet: false },
		{ merge: true },
	);
	batch.commit();
};

export async function takeNextCustomer(store: string, queue: string) {
	const snapshot = await getDocs(
		query(
			collection(getQueueRef(store, queue), "queue"),
			orderBy("queueNumber"),
			limit(1),
		),
	);

	if (snapshot.empty) {
		throw new Error("Last in queue");
	}
	const nextNumberId = snapshot.docs[0].id;

	const tRes = await runTransaction(firestore, async (t) => {
		const queueDoc = await t.get(getQueueNumberRef(store, queue, nextNumberId));
		const queueNumberData = queueDoc.data();
		if (!queueNumberData) {
			throw new Error("Missing queue number data");
		}
		const { queueNumber, ...nextNumberData } = queueNumberData;
		t.set(
			getQueueRef(store, queue),
			{
				state: {
					currentNumber: queueNumber,
					currentNumberID: nextNumberId,
					currentNumberData: nextNumberData,
					currentNumberServed: serverTimestamp(),
					count: increment(-1),
				},
			},
			{ merge: true },
		);
		t.delete(getQueueNumberRef(store, queue, nextNumberId));
	});
	return tRes;
}

export async function takeChosenCustomer(
	store: string,
	queue: string,
	customerID: string,
) {
	const queueDoc = await getDoc(getQueueRef(store, queue));
	const queueData = queueDoc.data();

	if (!queueData) {
		throw new Error("Missing queue data");
	}

	const snapshot = await getDoc(getQueueNumberRef(store, queue, customerID));
	if (!snapshot.exists()) {
		throw new Error("Queuer does not exist");
	}
	const { queueNumber, ...queueNumberData } = snapshot.data();

	await setDoc(
		getQueueRef(store, queue),
		{
			state: {
				currentNumber: queueNumber,
				currentNumberID: customerID,
				currentNumberData: queueNumberData,
				currentNumberServed: serverTimestamp(),
				count: increment(-1),
			},
		},
		{ merge: true },
	);

	await deleteDoc(getQueueNumberRef(store, queue, customerID));
}

export const uploadLogo = async (store: string, file: Blob) => {
	const logoRef = ref(storage, `stores/${store}/logo.png`);
	return await uploadBytes(logoRef, file);
};

export const openQueue = async (
	store: string,
	queue: string,
	queueData: QueueData,
) => {
	const openQueueData: QueueState = {
		currentNumber: 100,
		currentNumberID: null,
		currentNumberData: null,
		currentNumberServed: null,
		status: "open",
		count: 0,
		nextNumber: 101,
	};
	const updateObject =
		queueData.state.count > 0
			? { "state.status": "open" }
			: { state: openQueueData };
	await updateDoc(getQueueRef(store, queue), updateObject);
};

export const closeQueueForNewCustomers = async (store: string, queue: string) =>
	await updateDoc(getQueueRef(store, queue), {
		"state.status": "closing",
	});

export const closeQueue = async (store: string, queue: string) =>
	await getDocs(collection(getQueueRef(store, queue), "queue")).then(
		async (querySnapshot) => {
			querySnapshot.docs.forEach((snapshot) => {
				deleteDoc(snapshot.ref);
			});
			const closeQueueData: QueueState = {
				currentNumber: 100,
				currentNumberID: null,
				currentNumberData: null,
				currentNumberServed: null,
				status: "closed",
				count: 0,
				nextNumber: 101,
			};
			await updateDoc(getQueueRef(store, queue), { state: closeQueueData });
		},
	);

// STORE ACTIONS
export const getStores = async () => {
	return await getDocs(getStoresRef());
};
export const getStore = async (store: string) => {
	return await getDoc(getStoreRef(store));
};
export const updateStore = async (
	store: string,
	data: UpdateData<StoreData>,
) => {
	await updateDoc(getStoreRef(store), data);
};
export const deleteStore = async (store: string) => {
	await deleteDoc(getStoreRef(store));
};
export const addSupportedLanguage = async (store: string, lang: string) => {
	await updateDoc(getStoreRef(store), { supportedLangs: arrayUnion(lang) });
};
export const removeSupportedLanguage = async (store: string, lang: string) => {
	await updateDoc(getStoreRef(store), { supportedLangs: arrayRemove(lang) });
};

// QUEUE ACTIONS
export const createQueue = async (
	store: string,
	displayName: string,
	shortName: string,
	translations: QueueTranslations,
) => {
	const initialQueueData: QueueData = {
		state: {
			count: 0,
			currentNumber: 100,
			currentNumberID: null,
			currentNumberData: null,
			currentNumberServed: null,
			nextNumber: 101,
			status: "closed",
		},
		displayName,
		shortName,
		translations,
	};
	await setDoc(getQueueRef(store, shortName), initialQueueData);
};

export const getQueues = async (store: string) => {
	return await getDocs(getQueuesRef(store));
};
export const getQueue = async (store: string, queue: string) => {
	return await getDoc(getQueueRef(store, queue));
};
export const updateQueue = async (
	store: string,
	queue: string,
	data: UpdateData<QueueData>,
) => {
	await updateDoc(getQueueRef(store, queue), data);
};
export const deleteQueue = async (store: string, queue: string) => {
	await deleteDoc(getQueueRef(store, queue));
	// if queue had been added to any groups, remove it from them as well
	await getGroups(store).then(async (querySnapshot) => {
		querySnapshot.docs.forEach(async (doc) => {
			await removeQueueFromGroup(store, doc.data().shortName, queue);
		});
	});
};

// GROUP ACTIONS
export const createGroup = async (
	store: string,
	displayName: string,
	shortName: string,
) => {
	const initialGroupData: GroupData = {
		displayName,
		shortName,
		queues: [],
	};
	await setDoc(getGroupRef(store, shortName), initialGroupData);
};

export const getGroups = async (store: string) => {
	return await getDocs(getGroupsRef(store));
};
export const getGroup = async (store: string, group: string) => {
	return await getDoc(getGroupRef(store, group));
};
export const updateGroup = async (
	store: string,
	group: string,
	data: UpdateData<GroupData>,
) => {
	await updateDoc(getGroupRef(store, group), data);
};
export const deleteGroup = async (store: string, group: string) => {
	await deleteDoc(getGroupRef(store, group));
};
export const addQueueToGroup = async (
	store: string,
	group: string,
	queue: string,
) => {
	await updateDoc(getGroupRef(store, group), { queues: arrayUnion(queue) });
};
export const removeQueueFromGroup = async (
	store: string,
	group: string,
	queue: string,
) => {
	await updateDoc(getGroupRef(store, group), { queues: arrayRemove(queue) });
};

// QUEUE NUMBER ACTIONS
export const getQueueNumbers = async (store: string, queue: string) => {
	return await getDocs(getQueueNumbersRef(store, queue));
};
export const getQueueNumber = async (
	store: string,
	queue: string,
	queueNumber: string,
) => {
	return await getDoc(getQueueNumberRef(store, queue, queueNumber));
};
export const updateQueueNumber = async (
	store: string,
	queue: string,
	queueNumber: string,
	data: UpdateData<QueueNumberData>,
) => {
	await updateDoc(getQueueNumberRef(store, queue, queueNumber), data);
};
export const deleteQueueNumber = async (
	store: string,
	queue: string,
	queueNumber: string,
) => {
	await deleteDoc(getQueueNumberRef(store, queue, queueNumber));
};

// PII ACTIONS
export const getPiiData = async (
	store: string,
	queue: string,
	queueNumber: string,
) => {
	return await getDoc(getPiiRef(store, queue, queueNumber));
};
export const addPiiData = async (
	store: string,
	queue: string,
	queueNumber: string,
	data: PiiPhoneData,
) => {
	await setDoc(getPiiRef(store, queue, queueNumber), data);
};
export const getPiiCustomData = async (
	store: string,
	queue: string,
	queueNumber: string,
) => {
	return await getDoc(getPiiCustomDataRef(store, queue, queueNumber));
};
export const addPiiCustomData = async (
	store: string,
	queue: string,
	queueNumber: string,
	data: PiiCustomData,
) => {
	await setDoc(getPiiCustomDataRef(store, queue, queueNumber), data);
};

// ADMIN ACTIONS
export const adminCreateUser = async (data: {
	email: string;
	password: string;
}) => {
	const callCreateUser = httpsCallable(functions, "adminCreateUser");
	await callCreateUser({ email: data.email, password: data.password });
};

export const adminCreateStore = async (data: {
	name: string;
	slug: string;
	country: string;
	lang: string;
	supportedLangs: string[];
}) => {
	const callCreateStore = httpsCallable(functions, "adminCreateStore");
	if (!data.name || !data.slug || !data.country || !data.lang) {
		throw new Error("Missing data when creating store.");
	}
	await callCreateStore({
		name: data.name,
		country: data.country,
		lang: data.lang,
		supportedLangs: data.supportedLangs,
		slug: data.slug,
	});
};

export const adminSetUserClaim = async (data: {
	userId: string;
	claim: {
		store: string;
	};
}) => {
	const callSetUserClaim = httpsCallable(functions, "adminSetUserClaim");
	await callSetUserClaim({ userId: data.userId, claim: data.claim });
};

export const adminGetUsers = async () => {
	const callGetUsers = httpsCallable<unknown, { users: User[] }>(
		functions,
		"adminGetUsers",
	);
	return await callGetUsers();
};

export const adminDeleteUser = async (data: {
	userId: string;
}) => {
	const callDeleteUser = httpsCallable(functions, "adminDeleteUser");
	await callDeleteUser({ uid: data.userId });
};

// ANALYTICS ACTIONS

export const getAnalyticsData = async (data: {
	startDate: string;
	endDate: string;
	store: string;
}) => {
	const callGetAnalyticsData = httpsCallable<unknown, [QueriedAnalyticsData[]]>(
		functions,
		"getAnalyticsData",
	);
	return await callGetAnalyticsData({
		startDate: data.startDate,
		endDate: data.endDate,
		store: data.store,
	});
};

// OTHER ACTIONS

export const createStore = async (data: {
	name: string;
	shortName: string;
	country: string;
	lang: SupportedLangs;
	supportedLangs: SupportedLangs[];
}) => {
	const callCreateStore = httpsCallable<
		any,
		{ success: boolean; error?: string } | undefined
	>(functions, "createStore");

	return await callCreateStore({
		name: data.name,
		slug: data.shortName,
		country: data.country,
		lang: data.lang,
		supportedLangs: data.supportedLangs,
	});
};

export const postReviewData = async (data: ReviewData) => {
	const callPostReviewData = httpsCallable(functions, "postReviewData");
	await callPostReviewData(data);
};
