import { Navigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import AlertAutoEmpty from "../components/shared/AlertAutoEmpty";

export default function StoreLayout({
	claimStore,
	admin,
}: { claimStore: string; admin: boolean }) {
	const params = useParams();
	const store = params.store as string;
	document.title = "Flowby"; // to reset when title changed in queue view

	return claimStore !== store && !admin ? (
		<Navigate to={`/${claimStore}`} />
	) : (
		<>
			<AlertAutoEmpty store={store} />
			<Outlet context={{ store }} />
		</>
	);
}
