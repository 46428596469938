import { Box, type ResponsiveValue, Text } from "@chakra-ui/react";
import { type QueueData, db, supportedLangs } from "@flowby/firebase";
import { useDocData } from "../../libs/firebaseHooks";

// TODO: Handle errors better
export default function CustomData({
	store,
	queueData,
	queue,
	currentNumberId,
	fontSize = "lg",
}: {
	store: string;
	queueData: QueueData;
	queue: string;
	currentNumberId: string;
	fontSize?: ResponsiveValue<string> | string;
}) {
	const [piiCustomData] = useDocData(
		db.getPiiCustomDataRef(store, queue, currentNumberId),
		[store, queue, currentNumberId],
	);

	return (
		<Box textAlign="center">
			{piiCustomData?.data &&
				queueData.customDataFields &&
				Object.keys(piiCustomData.data)
					.sort()
					.map((key) => {
						const customField = queueData.customDataFields?.[key];
						if (!customField) {
							return undefined;
						}
						const fieldName = customField.en
							? customField.en.name
							: supportedLangs
									.map((lang) =>
										customField[lang] ? customField[lang].name : undefined,
									)
									.filter((name) => name !== undefined)[0];
						if (customField && piiCustomData.data[key] !== "") {
							return (
								<Text key={key} fontSize={fontSize}>
									<span style={{ textDecoration: "underline" }}>
										{fieldName}
									</span>
									: {piiCustomData.data[key]}
								</Text>
							);
						}
					})}
		</Box>
	);
}
