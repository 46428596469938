import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from "@chakra-ui/react";
import type { QueueData } from "@flowby/firebase";
import { type StoreData, db } from "@flowby/firebase";
import { useState } from "react";
import ConfirmAlert from "../../shared/ConfirmAlert";
import FlowbyError from "../../shared/Error";
import ModalContainer from "../../shared/ModalContainer";
import { useToast } from "../../shared/Toast";
import CreateDataField from "./CreateDataField";
import EditDataField from "./EditDataField";

export default function AddDataField({
	store,
	queue,
	storeData,
	queueData,
}: {
	store: string;
	queue: string;
	storeData: StoreData;
	queueData: QueueData;
}) {
	const [selectedDataField, setSelectedDataField] = useState<string | null>(
		null,
	);
	const [deleteDataFieldLoading, setDeleteGroupLoading] = useState(false);
	const createDataFieldDisclosure = useDisclosure();
	const editDataFieldDisclosure = useDisclosure();
	const deleteDataFieldDisclosure = useDisclosure();
	const toast = useToast();

	const onRemoveCustomDataField = async () => {
		if (queueData.customDataFields && selectedDataField) {
			const newCustomFields = queueData.customDataFields;
			delete newCustomFields[selectedDataField];
			try {
				db.updateQueue(store, queue, { customDataFields: newCustomFields });
				toast("success", "Data field removed.");
			} catch (e) {
				toast(
					"error",
					null,
					"CreateEditDataField/error-removing-data-field",
					e,
				);
			}
		}
		setSelectedDataField(null);
		setDeleteGroupLoading(false);
		deleteDataFieldDisclosure.onClose();
	};
	const customDataFields = queueData.customDataFields;
	const getQuestionText = () => {
		const customField =
			customDataFields &&
			selectedDataField &&
			customDataFields[selectedDataField];
		// due to weirdness with types
		// TODO: Look into removing this !== ""
		const fieldName =
			customField !== "" && customField?.en ? customField.en.name : "";
		return `Are you sure you want to delete the data field "${fieldName}"?`;
	};

	return (
		<FormControl id="customData">
			<ModalContainer
				isOpen={createDataFieldDisclosure.isOpen}
				onClose={createDataFieldDisclosure.onClose}
				header="Add Data Field"
				content={
					<CreateDataField
						store={store}
						queue={queue}
						storeData={storeData}
						onFinish={createDataFieldDisclosure.onClose}
					/>
				}
			/>
			<ModalContainer
				isOpen={editDataFieldDisclosure.isOpen}
				onClose={editDataFieldDisclosure.onClose}
				header="Edit Data Field"
				content={
					selectedDataField ? (
						<EditDataField
							store={store}
							queue={queue}
							selectedDataField={selectedDataField}
							queueData={queueData}
							storeData={storeData}
							onFinish={editDataFieldDisclosure.onClose}
						/>
					) : (
						<FlowbyError />
					)
				}
			/>
			<ConfirmAlert
				disclosure={deleteDataFieldDisclosure}
				headerText="Delete Data Field"
				questionText={getQuestionText()}
				noText="No"
				yesText="Yes"
				yesLoading={deleteDataFieldLoading}
				yesAction={onRemoveCustomDataField}
			/>
			<FormLabel>Customer Data Input</FormLabel>
			<FormHelperText>
				This allows you to collect customer input through a popup window shown
				to the customer when entering the queue. This could for example be an
				order number for easier handling of click and collect customer flows.
			</FormHelperText>
			<Box my={2} borderWidth={1} borderRadius={"md"} borderColor={"gray.200"}>
				<Table variant="unstyled">
					<Thead>
						<Tr borderBottomWidth={1}>
							<Th>Data Fields</Th>
						</Tr>
					</Thead>
					<Tbody>
						{queueData.customDataFields &&
							Object.keys(queueData.customDataFields)
								.sort()
								.map((fieldId: string) => {
									if (queueData.customDataFields) {
										const field = queueData.customDataFields[fieldId];
										const fieldLanguage = field?.[storeData.lang];
										return (
											<Tr borderBottomWidth={1} key={fieldId}>
												<Td>
													<Stack direction="row">
														<Text>
															{fieldLanguage ? fieldLanguage.name : ""}
															{field?.required ? "*" : ""}
														</Text>
														<Button
															variant="outline"
															size="xs"
															onClick={() => {
																setSelectedDataField(fieldId);
																editDataFieldDisclosure.onOpen();
															}}
														>
															Edit
														</Button>
														<Button
															variant="outline"
															size="xs"
															colorScheme="red"
															onClick={() => {
																setSelectedDataField(fieldId);
																deleteDataFieldDisclosure.onOpen();
															}}
														>
															Remove
														</Button>
													</Stack>
												</Td>
											</Tr>
										);
									}
								})}
						{(!queueData.customDataFields ||
							Object.keys(queueData.customDataFields).length === 0) && (
							<Tr borderBottomWidth={1}>
								<Td>
									<Stack direction="row">
										<Text fontSize="sm" color="gray.600">
											No data fields have been added. To add a data field click
											the add button below.
										</Text>
									</Stack>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
			<Stack direction="row" alignItems="center">
				<Button
					size="md"
					onClick={() => {
						createDataFieldDisclosure.onOpen();
					}}
				>
					Add
				</Button>
			</Stack>
		</FormControl>
	);
}
