import { Flex, Text } from "@chakra-ui/react";

export default function Footer({ size = "none" }: { size?: string }) {
	return (
		<Flex alignItems="center" justifyContent="center">
			<Text display={size === "none" ? "none" : "block"} fontSize={size}>
				Powered by flowby.io
			</Text>
		</Flex>
	);
}
