import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { hours } from "./analyticsCalculations";
import type { AggregatedAnalyticsData } from "./types";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);

export const options = {
	plugins: {
		title: {
			display: false,
			text: "Created Queue Numbers",
		},
	},
	responsive: true,
	interaction: {
		mode: "index" as const,
		intersect: false,
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
			stacked: true,
		},
		y: {
			grid: {
				display: false,
			},
			stacked: true,
		},
	},
};

export function HourChart({
	hourAggregatedAnalyticsData,
}: { hourAggregatedAnalyticsData: AggregatedAnalyticsData[] }) {
	const labels = hours;

	const data = {
		labels,
		datasets: [
			{
				label: "Digital Numbers",
				data: hourAggregatedAnalyticsData.map(
					(d) => d.totalCount - d.manualCount,
				),
				backgroundColor: "rgb(255, 99, 132)",
				stack: "Stack 0",
			},
			{
				label: "Manual Numbers",
				data: hourAggregatedAnalyticsData.map((d) => d.manualCount),
				backgroundColor: "rgb(75, 192, 192)",
				stack: "Stack 0",
			},
			{
				label: "SMS Sent",
				data: hourAggregatedAnalyticsData.map((d) => d.smsSentCount),
				backgroundColor: "rgb(53, 162, 235)",
				stack: "Stack 1",
			},
			{
				label: "Data Input",
				data: hourAggregatedAnalyticsData.map((d) => d.dataInputCount),
				backgroundColor: "rgb(255, 207, 86)",
				stack: "Stack 2",
			},
		],
	};
	return <Bar options={options} data={data} />;
}
