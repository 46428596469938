import {
	Container,
	HStack,
	Heading,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from "@chakra-ui/react";
import type { GroupData, QueueData, StoreData } from "@flowby/firebase";
import { IoBarChartOutline, IoConstructOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../shared/Toast";
import Analytics from "./Analytics/Analytics";
import General from "./General/General";
import Groups from "./Groups/Groups";
import Queues from "./Queues/Queues";

export default function StoreAdmin({
	storeData,
	queuesData,
	groupsData,
	store,
}: {
	storeData: StoreData;
	queuesData: QueueData[];
	groupsData: GroupData[];
	store: string;
}) {
	const location = useLocation();
	const navigate = useNavigate();
	const tabUrls = [`/${store}/admin/settings`, `/${store}/admin/analytics`];
	const toast = useToast();
	const defaultIndex =
		tabUrls.indexOf(location.pathname) === -1
			? 0
			: tabUrls.indexOf(location.pathname);

	return (
		<Container maxW="5xl" data-testid="store-admin-container">
			<Stack spacing={4} py={4}>
				<Heading textAlign="center">{storeData.name} Admin</Heading>
				<Tabs
					isLazy
					isFitted
					defaultIndex={defaultIndex}
					onChange={(index) => {
						const tabUrlsIndex = tabUrls[index];
						if (tabUrlsIndex) {
							navigate(tabUrlsIndex);
						} else {
							toast("error", null, "StoreAdmin/invalid-tab-index");
						}
					}}
				>
					<TabList>
						<Tab>
							<HStack>
								<IoConstructOutline size={24} /> <Text>Settings</Text>
							</HStack>
						</Tab>
						<Tab>
							<HStack>
								<IoBarChartOutline size={24} /> <Text>Analytics</Text>
							</HStack>
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<General
								store={store}
								storeData={storeData}
								queuesData={queuesData}
							/>
							<Queues
								store={store}
								storeData={storeData}
								queuesData={queuesData}
							/>
							<Groups
								store={store}
								storeData={storeData}
								queuesData={queuesData}
								groupsData={groupsData}
							/>
						</TabPanel>
						<TabPanel>
							<Analytics
								store={store}
								storeData={storeData}
								queuesData={queuesData}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Stack>
		</Container>
	);
}
