import { Button, Heading, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function Success() {
	const navigate = useNavigate();

	return (
		<Stack textAlign="center" spacing={4}>
			<Heading fontSize={"xl"}>Welcome to Flowby!</Heading>
			<Text>We hope you will have a great experience using our product.</Text>
			<Text>
				Keep in mind that you can always contact us at hello@flowby.io if you
				need guidance or have feedback to provide us.
			</Text>
			<Text>
				Now it's time to setup your queues. Click the button below to get
				started.
			</Text>
			<Button
				onClick={async () => {
					navigate("/");
				}}
			>
				Enter flowby
			</Button>
		</Stack>
	);
}
