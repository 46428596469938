import en from "./translations.en.json";
import fi from "./translations.fi.json";
import no from "./translations.no.json";
import sv from "./translations.sv.json";

export const translations = {
	en,
	sv,
	no,
	fi,
};
