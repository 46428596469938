import { Box, Image } from "@chakra-ui/react";
import { theme } from "@flowby/general";
import "./Spinner.css";

const spinnerSizes = {
	sm: {
		spinnerDiv: 16,
		spinnerImage: 10,
		spinnerThickness: 4,
		offset: -16,
		imageOffset: 1,
		imagePadding: 3,
	},
	md: {
		spinnerDiv: 32,
		spinnerImage: 20,
		spinnerThickness: 6,
		offset: -32,
		imageOffset: 2,
		imagePadding: 6,
	},
	lg: {
		spinnerDiv: 48,
		spinnerImage: 32,
		spinnerThickness: 8,
		offset: -48,
		imageOffset: 3,
		imagePadding: 8,
	},
};

export const Spinner = ({ size }: { size: "sm" | "md" | "lg" }) => {
	const emptyColor = theme.colors.colors.gray[100];

	const spinnerStyles = {
		display: "inline-block",
		borderColor: theme.colors.colors.gray[600],
		borderStyle: "solid",
		borderRadius: "99999px",
		borderWidth: spinnerSizes[size].spinnerThickness,
		borderBottomColor: emptyColor,
		borderLeftColor: emptyColor,
	};

	return (
		<Box ml={spinnerSizes[size].offset}>
			<Box
				position="absolute"
				className="flowby-spinner"
				__css={spinnerStyles}
				w={spinnerSizes[size].spinnerDiv}
				h={spinnerSizes[size].spinnerDiv}
			/>
			<Box
				p={spinnerSizes[size].imagePadding}
				ml={spinnerSizes[size].imageOffset}
				position="absolute"
				textAlign="center"
			>
				<Image
					src="/images/flowby-symbol-green-small.png"
					blockSize={spinnerSizes[size].spinnerImage}
					alt="Flowby Logo"
					fit="cover"
				/>
			</Box>
		</Box>
	);
};
