import {
	BarController,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineController,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import { Chart as ReactChartJS } from "react-chartjs-2";
import type { AggregatedAnalyticsData } from "./types";
import { average, filterUndefined } from "./utils";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	LineController,
	BarController,
	Title,
	Tooltip,
	Legend,
);

export const options = {
	plugins: {
		title: {
			display: false,
			text: "Created Queue Numbers",
		},
	},
	responsive: true,
	interaction: {
		mode: "index" as const,
		intersect: false,
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
			stacked: true,
		},
		y: {
			grid: {
				display: false,
			},
			stacked: true,
		},
		y1: {
			type: "linear" as const,
			display: true,
			position: "right" as const,
			grid: {
				display: false,
			},
		},
	},
};

export function Chart({
	dates,
	aggregatedAnalyticsData,
	waitTimes,
}: {
	dates: string[];
	aggregatedAnalyticsData: AggregatedAnalyticsData[];
	waitTimes: { dateTime: Date; date: string; wait: number }[];
}) {
	const dateWaitTimes =
		waitTimes &&
		dates.map((date) => {
			const wts = filterUndefined(
				waitTimes.map((wt) => {
					return wt.date === date ? wt.wait : undefined;
				}),
			);
			if (wts.length > 0) {
				const waitTime = Math.round(average(wts) / 1000 / 60);
				return waitTime;
			}
			return 0;
		});

	const labels = dates;
	const data = {
		labels,
		datasets: [
			{
				yAxisID: "y",
				label: "Digital Numbers",
				data: aggregatedAnalyticsData.map((d) => d.totalCount - d.manualCount),
				backgroundColor: "rgb(255, 99, 132)",
				stack: "Stack 0",
			},
			{
				yAxisID: "y",
				label: "Manual Numbers",
				data: aggregatedAnalyticsData.map((d) => d.manualCount),
				backgroundColor: "rgb(75, 192, 192)",
				stack: "Stack 0",
			},
			{
				yAxisID: "y",
				label: "SMS Sent",
				data: aggregatedAnalyticsData.map((d) => d.smsSentCount),
				backgroundColor: "rgb(53, 162, 235)",
				stack: "Stack 1",
			},
			{
				yAxisID: "y",
				label: "Data Input",
				data: aggregatedAnalyticsData.map((d) => d.dataInputCount),
				backgroundColor: "rgb(255, 207, 86)",
				stack: "Stack 2",
			},
			{
				yAxisID: "y1",
				type: "line" as const,
				label: "Average Wait Time",
				data: dateWaitTimes,
				backgroundColor: "rgb(143, 131, 195)",
				borderColor: "rgb(143, 131, 195)",
			},
		],
	};
	return <ReactChartJS type="bar" options={options} data={data} />;
}
