import { Link, List, ListIcon, ListItem, Stack, Text } from "@chakra-ui/react";
import { IoBook, IoCall, IoMail } from "react-icons/io5";

export default function Support() {
	return (
		<Stack pb={4}>
			<Text>
				Need help? Please read the guides in our help center or contact us
				directly via email or phone.
			</Text>
			<br />
			<List spacing={3}>
				<ListItem>
					<ListIcon fontSize="lg" as={IoBook} color="green.600" />
					<Link href="https://flowby.tawk.help/" isExternal color="green.700">
						Visit Flowby Help Center
					</Link>
				</ListItem>
				<ListItem>
					<ListIcon fontSize="lg" as={IoMail} color="green.600" />
					hello@flowby.io
				</ListItem>
				<ListItem>
					<ListIcon fontSize="lg" as={IoCall} color="green.600" />
					<Link href="tel:+46851942409" isExternal>
						+46 8519 424 09
					</Link>
				</ListItem>
			</List>
		</Stack>
	);
}
