import { Box, Flex } from "@chakra-ui/react";
import { theme } from "@flowby/general";
import { IoPeople } from "react-icons/io5";
import UpdateValueAnimation from "../shared/UpdateValueAnimation";

export default function QueuersCount({ count }: { count: number }) {
	return (
		<Box>
			<Flex justify="center">
				<IoPeople size={18} color={theme.colors.colors.green["600"]} />
			</Flex>
			<Box>
				<UpdateValueAnimation animateOnChange={[count]}>
					<Box px={2} fontWeight={600} fontSize="2xl">
						{count}
					</Box>
				</UpdateValueAnimation>
			</Box>
		</Box>
	);
}
