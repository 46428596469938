import {
	Divider,
	FormControl,
	FormHelperText,
	FormLabel,
	Select,
	Stack,
} from "@chakra-ui/react";
import type {
	QueriedAnalyticsData,
	QueueData,
	StoreData,
} from "@flowby/firebase";
import { db } from "@flowby/firebase";
import { useEffect, useState } from "react";
import Loader from "../../shared/Loader";
import { useToast } from "../../shared/Toast";
import AnalyticsDataView from "./AnalyticsDataView";
import { RangeDatepicker } from "./DatePicker";
import {
	getDateArrayBetweenTwoDates,
	getDateString,
	getLast30DaysDates,
} from "./utils";

const last30Days = getLast30DaysDates();

export default function Analytics({
	store,
	storeData,
	queuesData,
}: {
	store: string;
	storeData: StoreData;
	queuesData: QueueData[];
}) {
	const [analyticsData, setAnalyticsData] = useState<
		QueriedAnalyticsData[] | null
	>(null);
	const [filteredAnalyticsData, setFilteredAnalyticsData] = useState<
		QueriedAnalyticsData[] | null
	>(null);
	const [dateRange, setDateRange] = useState<Date[]>(last30Days);
	const [selectedQueue, setSelectedQueue] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const dates =
		dateRange[0] &&
		dateRange[1] &&
		getDateArrayBetweenTwoDates(
			getDateString(dateRange[0]),
			getDateString(dateRange[1]),
		);
	const toast = useToast();

	const getAnalyticsData = async () => {
		if (dateRange[0] && dateRange[1]) {
			setLoading(true);
			try {
				const response = await db.getAnalyticsData({
					startDate: getDateString(dateRange[0]),
					endDate: getDateString(dateRange[1]),
					store: store,
				});
				if (!response.data[0]) {
					throw new Error("No data returned.");
				}
				setLoading(false);
				return response.data[0];
			} catch (e) {
				setLoading(false);
				toast("error", null, "Analytics/error-fetching data", e);
				return null;
			}
		}
		return null;
	};

	useEffect(() => {
		if (dateRange.length > 1) {
			setAnalyticsData(null);
			getAnalyticsData().then((analyticsData) => {
				setAnalyticsData(analyticsData);
			});
		}
	}, [dateRange]);

	useEffect(() => {
		if (dates && dates.length > 0 && analyticsData) {
			const filteredAnalyticsData =
				selectedQueue === ""
					? analyticsData
					: analyticsData?.filter(
							(dataPoint) => dataPoint.queue === selectedQueue,
						);
			setFilteredAnalyticsData(filteredAnalyticsData);
		}
	}, [analyticsData, selectedQueue]);

	const today = new Date();

	return (
		<Stack spacing={4} pb={2}>
			<FormControl id="analytics">
				<FormLabel>Options</FormLabel>
				<FormHelperText>
					Select the date range and which queues to view data for below.
				</FormHelperText>
				<Stack spacing={2}>
					<RangeDatepicker
						selectedDates={dateRange}
						onDateChange={(dates) => {
							setDateRange(dates);
						}}
						maxDate={today}
					/>
					<Select
						placeholder="All Queues"
						onChange={(e) => {
							setSelectedQueue(e.target.value);
						}}
					>
						{queuesData.map((queue) => (
							<option key={queue.shortName} value={queue.shortName}>
								{queue.displayName}
							</option>
						))}
					</Select>
					<Divider pt={2} />
					{(!dates || !filteredAnalyticsData || loading) && <Loader />}
					{!loading && filteredAnalyticsData && dates && (
						<AnalyticsDataView
							storeData={storeData}
							analyticsData={filteredAnalyticsData}
							dates={dates}
						/>
					)}
				</Stack>
			</FormControl>
		</Stack>
	);
}
