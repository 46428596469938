import {
	type DataField,
	type DataFields,
	type QueueData,
	type SupportedLangs,
	isNotNull,
} from "@flowby/firebase";

type MissingTranslationDataField = {
	fieldId: string;
	displayName: string;
	primaryTranslations: DataField["en"];
};

export type MissingTranslations = {
	queueShortName: string;
	queueDisplayName: string;
	yourTurn: null | {
		primaryTranslation: string;
	};
	dataFields?: {
		[key: string]: MissingTranslationDataField;
	};
}[];

export const findFieldsMissingTranslations = (
	lang: SupportedLangs,
	primaryLang: SupportedLangs,
	queuesData: QueueData[],
): MissingTranslations => {
	const missing = queuesData.map((queueData) => {
		const dataFields = queueData.customDataFields as DataFields;
		const missingDataFields = dataFields
			? Object.keys(dataFields).reduce(
					(acc, key) => {
						const dataField = dataFields[key];
						if (dataField && !dataField[lang]) {
							acc[key] = {
								fieldId: key,
								displayName: dataField[primaryLang].name,
								primaryTranslations: dataField[primaryLang],
							};
						}
						return acc;
					},
					{} as { [key: string]: MissingTranslationDataField },
				)
			: undefined;

		const missingYourTurn = queueData.translations[lang]
			? null
			: {
					// yourTurn for primaryLang should always be defined
					primaryTranslation: queueData.translations[primaryLang]
						?.yourTurn as string,
				};

		if (
			!missingYourTurn &&
			(!missingDataFields || Object.keys(missingDataFields).length === 0)
		) {
			return null;
		}
		return {
			queueShortName: queueData.shortName,
			queueDisplayName: queueData.displayName,
			yourTurn: missingYourTurn,
			dataFields: missingDataFields,
		};
	});
	return missing.filter(isNotNull);
};
