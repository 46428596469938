import { Stack, Text } from "@chakra-ui/react";
import { db } from "@flowby/firebase";
import FormikForm, { type FormikFields } from "../../shared/FormikForm";
import { useToast } from "../../shared/Toast";
import type { MissingTranslations } from "./utils";

export default function AddMissingTranslationsForm({
	store,
	lang,
	missingTranslations,
	onFinish,
}: {
	store: string;
	lang: string | null;
	missingTranslations: MissingTranslations;
	onFinish: () => void;
}) {
	const toast = useToast();
	if (!lang) {
		return null;
	}

	const addTranslations = async (data: { [key: string]: any }) => {
		try {
			Object.keys(data).map(async (key) => {
				const [queueShortName, fieldKey] = key.split("$");
				if (!queueShortName || !fieldKey) {
					return;
				}
				const formattedFieldKey = fieldKey.replaceAll("|", ".");
				await db.updateQueue(store, queueShortName, {
					[formattedFieldKey]: data[key],
				});
			});
			onFinish();
		} catch (e) {
			toast(
				"error",
				null,
				"MissingTransalationsForm/add-missing-translations-error",
				e,
			);
		}
	};

	const fields = missingTranslations.reduce((acc, queueTranslation) => {
		acc.push({
			type: "label",
			id: queueTranslation.queueShortName,
			label: queueTranslation.queueDisplayName,
		});
		if (queueTranslation.yourTurn) {
			acc.push({
				type: "textInput",
				id: `${queueTranslation.queueShortName}$translations|${lang}|yourTurn`,
				helperText: `Call To Action Text. Your primary language translation is: "${queueTranslation.yourTurn.primaryTranslation}"`,
				validation: (value: string) => {
					if (!value || value.length === 0) {
						return "Translation is required.";
					}
					return undefined;
				},
			});
		}

		const dataFields = queueTranslation.dataFields;
		if (dataFields) {
			Object.keys(dataFields).map((key) => {
				const dataField = dataFields[key];
				if (dataField) {
					acc.push({
						type: "textInput",
						id: `${queueTranslation.queueShortName}$customDataFields|${key}|${lang}|name`,
						helperText: `${dataField.displayName} Name. Your primary language translation is: "${dataField.primaryTranslations.name}"`,
						validation: (value: string) => {
							if (!value || value.length === 0) {
								return "Translation is required.";
							}
							return undefined;
						},
					});
					acc.push({
						type: "textInput",
						id: `${queueTranslation.queueShortName}$customDataFields|${key}|${lang}|description`,
						helperText: `${dataField.displayName} Description. Your primary language translation is: "${dataField.primaryTranslations.description}"`,
						validation: (value: string) => {
							if (!value || value.length === 0) {
								return "Translation is required.";
							}
							return undefined;
						},
					});
					acc.push({
						type: "textInput",
						id: `${queueTranslation.queueShortName}$customDataFields|${key}|${lang}|placeholder`,
						helperText: `${dataField.displayName} Placeholder. Your primary language is: "${dataField.primaryTranslations.placeholder}"`,
						validation: (value: string) => {
							if (!value || value.length === 0) {
								return "Translation is required.";
							}
							return undefined;
						},
					});
				}
			});
		}
		return acc;
	}, [] as FormikFields);

	return (
		<Stack spacing={4}>
			<Text>
				To support this language you need to add the translations listed below
				in the chosen language.
			</Text>
			<FormikForm
				fields={fields}
				submitButtonText="Add translations"
				onSubmit={async (values) => {
					await addTranslations(values);
				}}
			/>
		</Stack>
	);
}
