import { Box, Button, Flex, Image, SimpleGrid } from "@chakra-ui/react";

export default function Header({
	leftComponent,
	logo,
	rightComponent,
}: {
	leftComponent: {
		text: string;
		icon: JSX.Element;
		onClick: () => void;
	} | null;
	logo?: JSX.Element;
	rightComponent: {
		text: string;
		icon: JSX.Element;
		onClick: () => void;
	} | null;
}) {
	return (
		<Flex
			width="100%"
			minH={"60px"}
			bgColor="white"
			py={{ base: 2 }}
			borderBottom={1}
			borderStyle={"solid"}
			borderColor={"gray.100"}
		>
			<SimpleGrid flexGrow={1} columns={3} spacing={0}>
				<Flex justify={{ base: "left", md: "center" }} alignItems="center">
					{leftComponent && (
						<Button
							px={2}
							iconSpacing={2}
							variant="ghost"
							leftIcon={leftComponent.icon}
							onClick={leftComponent.onClick}
						>
							{leftComponent.text}
						</Button>
					)}
				</Flex>

				<Flex justify="center" alignItems="center">
					<Box>
						{logo ? (
							logo
						) : (
							<Flex
								flex={{ base: 1 }}
								justify={{ base: "center", md: "start" }}
							>
								<Button
									aria-label="flowby-logo"
									variant="link"
									_hover={{ textDecoration: "none" }}
								>
									<Image
										src="/images/flowby-symbol-green-small.png"
										height={7}
										alt="Flowby Logo"
										fit="cover"
									/>
									<Box
										display={{ base: "none", md: "flex" }}
										color="green.500"
										textTransform="none"
										fontSize="2xl"
										fontWeight="900"
										pl={2}
									>
										flowby
									</Box>
								</Button>
							</Flex>
						)}
					</Box>
				</Flex>
				<Flex justify={{ base: "right", md: "center" }} alignItems="center">
					{rightComponent && (
						<Button
							px={2}
							variant="ghost"
							rightIcon={rightComponent.icon}
							onClick={rightComponent.onClick}
						>
							{rightComponent.text}
						</Button>
					)}
				</Flex>
			</SimpleGrid>
		</Flex>
	);
}
