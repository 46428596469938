import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
	Box,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	Stack,
	Text,
} from "@chakra-ui/react";
import type { GroupData, QueueData } from "@flowby/firebase";
import { useTranslation } from "react-i18next";
import QueueStatusBadge from "../shared/QueueStatusBadge";

export default function QueueSelector({
	groupData,
	queuesData,
	onFinish,
}: {
	groupData: GroupData;
	queuesData: QueueData[];
	onFinish: (queue: string) => void;
}) {
	const { t } = useTranslation();

	return (
		<Flex id="kiosk-group-selector" direction="column" minH={"60vh"}>
			<Container py={2}>
				<Stack py={{ base: 4 }} spacing={4}>
					<Heading fontSize="5xl" textAlign="center">
						{t("choose-queue")}
					</Heading>
					<Text fontSize="2xl" textAlign="center">
						{t("choose-queue-instruction")}
					</Text>
					{queuesData &&
						groupData &&
						queuesData.map((q) => {
							if (
								groupData.queues.includes(q.shortName) &&
								q.state.status === "open"
							) {
								return (
									<Box
										as={q.state.status === "open" ? Link : Box}
										id={`kiosk-group-selector-queue-${q.shortName}`}
										key={q.shortName}
										_hover={{ textDecoration: "none" }}
										onClick={() => {
											if (q.state.status === "open") {
												onFinish(q.shortName);
											}
										}}
									>
										<Grid
											templateColumns="repeat(6, 1fr)"
											p={5}
											shadow="md"
											borderWidth="1px"
											bg="white"
											borderRadius="0.375rem"
										>
											<GridItem colSpan={5}>
												<Heading size="lg">
													{q.displayName}
													<QueueStatusBadge status={q.state.status} />
												</Heading>
											</GridItem>
											<GridItem
												display="flex"
												colSpan={1}
												justifyContent="center"
												alignItems="center"
											>
												{q.state.status === "open" ? (
													<ArrowForwardIcon w={8} h={8} />
												) : null}
											</GridItem>
										</Grid>
									</Box>
								);
							}
							return null;
						})}
				</Stack>
			</Container>
		</Flex>
	);
}
