import {
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoEnterOutline } from "react-icons/io5";
/* eslint-disable indent */
import { useNavigate } from "react-router-dom";
import Header from "../components/shared/Header";

export default function ForgotPasswordPage() {
	const navigate = useNavigate();
	const [error, setError] = useState("");

	const logError = () => {
		console.error(error);
	};

	const throwError = () => {
		throw new Error(error);
	};

	const rollbarError = () => {
		Rollbar.error(error);
	};

	return (
		<Flex minH={"90vh"} flexDirection="column">
			<Header
				leftComponent={null}
				rightComponent={{
					text: "Login",
					icon: <IoEnterOutline size={24} />,
					onClick: () => navigate("/login"),
				}}
			/>
			<Container>
				<Stack
					spacing={8}
					mx={"auto"}
					maxW={"lg"}
					py={{ base: 4, md: 12 }}
					px={{ base: 0, md: 6 }}
				>
					<Stack align={"center"}>
						<Heading fontSize={"4xl"}>Error Testing</Heading>
						<Text fontSize="lg">
							This tests the error forwarding to flowby.
						</Text>
					</Stack>
					<Box
						rounded={"lg"}
						p={8}
						borderWidth="1px"
						bg="white"
						shadow="md"
						borderColor="gray.200"
						borderRadius="0.375rem"
					>
						<Stack spacing={4}>
							<FormControl id="error">
								<FormLabel>Error Message</FormLabel>
								<Input
									name="error"
									type="text"
									value={error}
									onChange={(e) => setError(e.target.value)}
								/>
							</FormControl>
							<Stack spacing={5}>
								<Button onClick={logError}>Log Error</Button>
								<Button colorScheme="red" onClick={throwError}>
									Throw Error
								</Button>
								<Button colorScheme="yellow" onClick={rollbarError}>
									Report Rollbar Error
								</Button>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Container>
		</Flex>
	);
}
