import { Flex } from "@chakra-ui/react";
import { Spinner } from "./Spinner";

const fullScreenMargins = {
	sm: 16 / 2,
	md: 32 / 2,
	lg: 48 / 2,
};

export default function Loader({
	fullscreen = false,
	size = "sm",
}: { fullscreen?: boolean; size?: "sm" | "md" | "lg" }) {
	return (
		<Flex
			mt={fullscreen ? -fullScreenMargins[size] : 0}
			height={fullscreen ? "100%" : "initial"}
			alignItems="center"
			justifyContent="center"
		>
			<Spinner size={size} />
		</Flex>
	);
}
