import {
	Box,
	Button,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Image,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
} from "@chakra-ui/react";
import {
	type QueueData,
	type StoreData,
	type SupportedLangs,
	db,
} from "@flowby/firebase";
import { translations } from "@flowby/general";
import { posthog } from "posthog-js";
import React, { useEffect, useState } from "react";
import { useToast } from "../../shared/Toast";
import { Tooltip } from "../../shared/Tooltip";
import Languages from "./Languages";
import StoreConfig from "./StoreConfig";

export default function General({
	storeData,
	queuesData,
	store,
}: {
	storeData: StoreData;
	queuesData: QueueData[];
	store: string;
}) {
	const [loading, setLoading] = useState(false);
	const [logoError, setLogoError] = useState(false);
	const [addLangSelect, setAddLangSelect] = useState<string | null>(null);
	const toast = useToast();
	const imageUpload = React.useRef<HTMLInputElement>(null);
	const availableLangs = Object.keys(translations);
	const supportedLangs = storeData.supportedLangs;
	const notSupportedLangs = availableLangs.filter(
		(lang) => !supportedLangs.includes(lang as SupportedLangs),
	);

	useEffect(() => {
		if (!addLangSelect) {
			setAddLangSelect(
				notSupportedLangs.length > 0 && notSupportedLangs[0]
					? notSupportedLangs[0]
					: null,
			);
		}
	});

	const onEditStoreData = async (data: { [key: string]: any }) => {
		try {
			await db.updateStore(store, data);
		} catch (e) {
			toast("error", null, "StoreAdmin/edit-store-error");
		}
	};

	const saveImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files?.[0]) {
			setLoading(true);
			const image = event.target.files[0];
			const uploadSuccess = await db.uploadLogo(store, image);
			setLoading(false);
			if (uploadSuccess) {
				location.reload();
				toast("success", "Store logo uploaded.");
			} else {
				toast(
					"error",
					"Failed to upload store logo.",
					"StoreAdmin/save-image-error",
				);
			}
		}
	};

	return (
		<Stack spacing={4} pb={2}>
			<FormControl id="storeLogo">
				<FormLabel>Logo</FormLabel>
				<FormHelperText>
					Add your logo to improve the customer experience. The logo will be
					displayed to your customers when they interact with your queues.
				</FormHelperText>
				<Box my={4} position="relative" textAlign="left" w={200}>
					{!logoError && (
						<Image
							role="presentation"
							maxW={202}
							fit="contain"
							src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === "dev" ? "flowby-io-dev" : "flowby-io"}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
							onError={() => setLogoError(true)}
						/>
					)}
				</Box>
				<Input
					ref={imageUpload}
					hidden={true}
					type="file"
					name="newStoreLogo"
					onChange={saveImage}
					style={{ border: "none" }}
				/>
				<Button
					size="sm"
					isLoading={loading}
					onClick={() => {
						imageUpload.current?.click();
					}}
				>
					{logoError ? "Upload" : "Change"} logo
				</Button>
			</FormControl>
			<Languages store={store} storeData={storeData} queuesData={queuesData} />
			<StoreConfig storeData={storeData} store={store} />
			{posthog.isFeatureEnabled("admin-feature-selection") && (
				<Box
					p={4}
					shadow="md"
					borderWidth="1px"
					bg="white"
					borderRadius="0.375rem"
				>
					<Text pb={2} color="gray.700">
						Flowby admin options (hidden for customers)
					</Text>
					<FormControl>
						<FormLabel>Features</FormLabel>
						<FormHelperText>
							Select the features you use. This will adapt the customer
							experience to suit your usage.
						</FormHelperText>
						<RadioGroup
							name="status"
							value={storeData.config?.status === false ? "0" : "1"}
							defaultValue="1"
							onChange={(yesNo) => {
								onEditStoreData({ config: { status: yesNo === "1" } });
							}}
						>
							<Flex mb={1} pt={1} align="center">
								<FormHelperText mr={1} my={0} fontSize="md">
									Status Screen
								</FormHelperText>
								<Tooltip label="Changes text shown on the kiosk screen." />
							</Flex>
							<Stack spacing={5} direction="row">
								<Radio colorScheme="green" value="1">
									Yes
								</Radio>
								<Radio colorScheme="green" value="0">
									No
								</Radio>
							</Stack>
						</RadioGroup>
					</FormControl>
				</Box>
			)}
		</Stack>
	);
}
