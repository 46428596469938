export const average = (array: number[]) =>
	array.reduce((a, b) => a + b) / array.length;

// So called type guard used to filter arrays for undefined and give correct type out
export function isDefined<T>(argument: T | undefined): argument is T {
	return argument !== undefined;
}

// This type guard is used to filter arrays and give correct type out, if only isDefined used it does
// not account for the face that the array can be empty
export function filterUndefined<T>(array: (T | undefined)[]): T[] {
	return array.filter(isDefined);
}

export const getDateArrayBetweenTwoDates = (
	startDate: string,
	endDate: string,
) => {
	const dates: string[] = [];
	const currDate = new Date(startDate);
	const end = new Date(endDate);
	while (currDate <= end) {
		const currDateString = currDate.toISOString().split("T")[0];
		if (currDateString) {
			dates.push(currDateString);
		}
		currDate.setUTCDate(currDate.getUTCDate() + 1);
	}
	return dates;
};

export const getLast30DaysDates: () => [Date, Date] = () => {
	const today = new Date();
	const lastWeek = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
	return [lastWeek, today];
};

export const getDateString = (date: Date) => {
	return `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;
};
