import {
	Box,
	Button,
	Container,
	Divider,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	GridItem,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import type { QueueData, StoreData } from "@flowby/firebase";
import { useState } from "react";
import ModalContainer from "../../shared/ModalContainer";
import CreateQueueForm from "./CreateQueueForm";
import EditQueueForm from "./EditQueueForm";

export default function Queues({
	storeData,
	queuesData,
	store,
}: {
	storeData: StoreData;
	queuesData: QueueData[];
	store: string;
}) {
	const createQueueDisclosure = useDisclosure();
	const editQueueDisclosure = useDisclosure();
	const [editQueue, setEditQueue] = useState<string | undefined>(undefined);

	const onFinishCreateQueue = (queue: string) => {
		createQueueDisclosure.onClose();
		setEditQueue(queue);
		editQueueDisclosure.onOpen();
	};

	return (
		<Stack spacing={4} pb={2}>
			<ModalContainer
				isOpen={createQueueDisclosure.isOpen}
				onClose={createQueueDisclosure.onClose}
				header="Create new queue"
				content={
					<CreateQueueForm
						store={store}
						storeData={storeData}
						queuesData={queuesData}
						onFinish={onFinishCreateQueue}
					/>
				}
			/>
			<ModalContainer
				footer={
					<Container pb={2}>
						<Divider />
						<Button
							size="md"
							mt={2}
							w="100%"
							variant="outline"
							onClick={editQueueDisclosure.onClose}
						>
							Close
						</Button>
					</Container>
				}
				isOpen={editQueueDisclosure.isOpen}
				onClose={editQueueDisclosure.onClose}
				header="Edit queue"
				content={
					editQueue ? (
						<EditQueueForm
							store={store}
							storeData={storeData}
							queue={editQueue}
							onFinish={editQueueDisclosure.onClose}
						/>
					) : (
						<Text>Something went wrong.</Text>
					)
				}
			/>
			<FormControl id="queues">
				<FormLabel>Queues</FormLabel>
				<FormHelperText>
					Queues lets your customers get in line to a service point or store
					checkout using a QR code.
				</FormHelperText>
				<Stack spacing={1} py={1}>
					{queuesData?.map((q) => (
						<Box key={q.shortName}>
							<Grid
								templateColumns="repeat(6, 1fr)"
								py={2}
								px={5}
								borderWidth="1px"
								borderRadius="0.375rem"
								_hover={{ textDecoration: "none" }}
							>
								<GridItem colSpan={5}>
									<Text size="lg">{q.displayName}</Text>
								</GridItem>
								<GridItem
									display="flex"
									colSpan={1}
									justifyContent="center"
									alignItems="center"
								>
									<Button
										data-testid={`edit-queue-button-${q.shortName}`}
										size="xs"
										variant="outline"
										onClick={() => {
											setEditQueue(q.shortName);
											editQueueDisclosure.onOpen();
										}}
									>
										Edit
									</Button>
								</GridItem>
							</Grid>
						</Box>
					))}
				</Stack>
				<Button
					data-testid="create-queue-button"
					size="sm"
					onClick={createQueueDisclosure.onOpen}
				>
					+ Create queue
				</Button>
			</FormControl>
		</Stack>
	);
}
