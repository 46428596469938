import { Box, Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";
import { useScreenSize } from "../../../libs/native";
import type { AggregatedAnalyticsData, WaitTime } from "./types";
import { average } from "./utils";

export function Stats({
	aggregatedAnalyticsData,
	waitTimes,
}: {
	aggregatedAnalyticsData: AggregatedAnalyticsData[];
	waitTimes: WaitTime[] | null;
}) {
	const totalNumbers = aggregatedAnalyticsData.reduce(
		(acc, curr) => {
			acc.totalCount += curr.totalCount;
			acc.manualCount += curr.manualCount;
			acc.kioskCount += curr.kioskCount;
			acc.smsSentCount += curr.smsSentCount;
			acc.dataInputCount += curr.dataInputCount;
			return acc;
		},
		{
			totalCount: 0,
			manualCount: 0,
			kioskCount: 0,
			smsSentCount: 0,
			dataInputCount: 0,
		},
	);
	const times = waitTimes?.map((wt) => wt.wait);
	const screenSize = useScreenSize();
	return (
		<Box p={4} mb={2} borderWidth="1px" textAlign="center" borderRadius="md">
			{screenSize !== "mobile" && (
				<StatGroup>
					<Stat>
						<StatLabel>Digital Numbers</StatLabel>
						<StatNumber>
							{totalNumbers.totalCount - totalNumbers.manualCount}
						</StatNumber>
					</Stat>

					<Stat>
						<StatLabel>Manual Numbers</StatLabel>
						<StatNumber>{totalNumbers.manualCount}</StatNumber>
					</Stat>

					<Stat>
						<StatLabel>SMS Sent</StatLabel>
						<StatNumber>{totalNumbers.smsSentCount}</StatNumber>
					</Stat>
					<Stat>
						<StatLabel>Data Input</StatLabel>
						<StatNumber>{totalNumbers.dataInputCount}</StatNumber>
					</Stat>
					<Stat>
						<StatLabel>Average Wait Time</StatLabel>
						<StatNumber>
							{times && times.length > 0
								? `${Math.round(average(times) / 1000 / 60)} min`
								: "-"}
						</StatNumber>
					</Stat>
				</StatGroup>
			)}
			{screenSize === "mobile" && (
				<Box>
					<Stat>
						<StatLabel>Digital Numbers</StatLabel>
						<StatNumber>
							{totalNumbers.totalCount - totalNumbers.manualCount}
						</StatNumber>
					</Stat>

					<Stat>
						<StatLabel>Manual Numbers</StatLabel>
						<StatNumber>{totalNumbers.manualCount}</StatNumber>
					</Stat>

					<Stat>
						<StatLabel>SMS Sent</StatLabel>
						<StatNumber>{totalNumbers.smsSentCount}</StatNumber>
					</Stat>
					<Stat>
						<StatLabel>Data Input</StatLabel>
						<StatNumber>{totalNumbers.dataInputCount}</StatNumber>
					</Stat>
					<Stat>
						<StatLabel>Average Wait Time</StatLabel>
						<StatNumber>
							{times && times.length > 0
								? `${Math.round(average(times) / 1000 / 60)} min`
								: "-"}
						</StatNumber>
					</Stat>
				</Box>
			)}
		</Box>
	);
}
