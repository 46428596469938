import {
	Box,
	Button,
	Container,
	Divider,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import { type QueueData, db } from "@flowby/firebase";
import QueueStatusBadge from "../shared/QueueStatusBadge";

export default function Settings({
	store,
	queue,
	queueData,
	onFinish,
}: {
	store: string;
	queue: string;
	queueData: QueueData;
	onFinish: () => void;
}) {
	return (
		<Container id="queue-settings" mb={2}>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={{ base: 4, md: 8 }}>
				<Box>
					<Box>
						<Heading size="lg">
							{queueData.displayName}
							<QueueStatusBadge ml={1} status={queueData.state.status} />
						</Heading>
						<Text color="gray.600">{queueData.shortName}</Text>
					</Box>
					<Divider mt={2} mb={4} />
					<Stack spacing={4}>
						<Stack>
							<Heading size="md">Status</Heading>
							<Button
								disabled={queueData.state.status === "open"}
								onClick={() => {
									db.openQueue(store, queue, queueData);
									onFinish();
								}}
							>
								Open queue
							</Button>
							<Button
								disabled={
									queueData.state.status === "closing" ||
									queueData.state.status === "closed"
								}
								colorScheme="yellow"
								onClick={() => {
									db.closeQueueForNewCustomers(store, queue);
									onFinish();
								}}
							>
								Close for new
							</Button>
							<Button
								disabled={queueData.state.status === "closed"}
								colorScheme="red"
								onClick={() => {
									db.closeQueue(store, queue);
									onFinish();
								}}
							>
								Close queue
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	);
}
