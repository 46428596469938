import { useEffect, useState } from "react";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/700.css";
import {
	ChakraProvider,
	extendTheme,
	withDefaultColorScheme,
} from "@chakra-ui/react";
import type { User } from "@flowby/firebase";
import { theme } from "@flowby/general";
import posthog from "posthog-js";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import ErrorComponent from "./components/shared/Error";
import Loader from "./components/shared/Loader";
import OfflineToast from "./components/shared/OfflineToast";
import StoreLayout from "./layouts/StoreLayout";
import { useAuthState } from "./libs/firebaseHooks";
import FlowbyAdminPage from "./pages/FlowbyAdminPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import GroupKioskPage from "./pages/GroupKioskPage";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import QueueKioskPage from "./pages/QueueKioskPage";
import QueuePage from "./pages/QueuePage";
import QueuersPage from "./pages/QueuersPage";
import SignupPage from "./pages/SignupPage";
import StoreAdminPage from "./pages/StoreAdminPage";
import StorePage from "./pages/StorePage";
import TestErrorReporting from "./pages/TestErrorReportingPage";
import "./i18n";
import { install } from "resize-observer";
import { setDefaultStatusBar } from "./libs/native";

// Polyfill for ResizeObserver
// Caused a crash when tab switching to analytics in admin on iOS app
if (!window.ResizeObserver) install();

const publicPaths = [
	"/login",
	"/signup",
	"/forgotpassword",
	"/create-store",
	"/kiosk",
];

const isPublicPath = () => {
	const firstPartOfUrl = `/${window.location.pathname.split("/")[1] || ""}`;
	return publicPaths.includes(firstPartOfUrl);
};

const chakraTheme = extendTheme(
	theme.styles,
	theme.components,
	theme.colors,
	withDefaultColorScheme({
		colorScheme: "teal",
	}),
);

export default function MyApp() {
	const [user, userLoading, userError] = useAuthState();
	const userWithType = user as undefined | User;
	const [store, setStore] = useState<string | null>(null);
	const [admin, setAdmin] = useState<boolean>(false);
	setDefaultStatusBar();
	if (userWithType) {
		Rollbar.configure({
			payload: {
				person: {
					id: userWithType.uid,
					email: userWithType.email ? userWithType.email : undefined,
				},
				store,
				admin,
			},
		});
		posthog.init(
			process.env.REACT_APP_ENV === "dev"
				? "phc_la4epskkakXBtLA1hpxXaJbsmn2QokI1P9AfswPe3WQ"
				: "phc_X94088begmlDh0MpAR7MyFaczTTo9bBXBpBAUyyXSyb",
			{ api_host: "https://eu.posthog.com" },
		);
		posthog.identify(userWithType.email || userWithType.uid, {
			store,
			admin,
		});
	}
	if (userError) {
		Rollbar.critical("AuthError", userError);
	}
	if (!userLoading && !userError && !user && !isPublicPath()) {
		window.location.replace("/login");
	}

	const updateStore = () => {
		if (userWithType) {
			userWithType.getIdTokenResult(true).then((idTokenResult) => {
				const claimStore = idTokenResult.claims.store as string;
				setStore(claimStore);
				if (idTokenResult.claims.admin) {
					setAdmin(true);
				}
			});
		} else {
			setStore(null);
			setAdmin(false);
		}
	};

	useEffect(() => {
		updateStore();
	}, [user]);

	return (
		<ChakraProvider theme={chakraTheme}>
			<div id="statusbar-fix" />
			<OfflineToast />
			{isPublicPath() || (userWithType && (store || admin)) ? (
				<BrowserRouter>
					<Routes>
						<Route
							path="/"
							element={<IndexPage admin={admin} store={store as string} />}
						/>
						<Route
							path="login"
							element={<LoginPage user={userWithType as User} />}
						/>
						<Route
							path="signup"
							element={
								<SignupPage
									user={userWithType as User}
									userLoading={userLoading}
									updateStore={updateStore}
								/>
							}
						/>
						<Route path="forgotpassword" element={<ForgotPasswordPage />} />
						<Route path="kiosk">
							<Route path=":store">
								<Route path="q">
									<Route path=":queue" element={<QueueKioskPage />} />
								</Route>
								<Route path="g">
									<Route path=":group" element={<GroupKioskPage />} />
								</Route>
							</Route>
						</Route>
						<Route
							path="flowby-admin"
							element={<FlowbyAdminPage admin={admin} />}
						/>
						<Route
							path=":store"
							element={
								<StoreLayout claimStore={store as string} admin={admin} />
							}
						>
							<Route index element={<StorePage />} />
							<Route path="admin/*" element={<StoreAdminPage />} />
							<Route path="q">
								<Route path=":queue">
									<Route index element={<QueuePage />} />
									<Route path="queuers" element={<QueuersPage />} />
								</Route>
							</Route>
						</Route>
						<Route path="error" element={<TestErrorReporting />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</BrowserRouter>
			) : (
				<>
					{userError && (
						<ErrorComponent
							text="Something went wrong. Contact hello@flowby.io for help."
							fullscreen={true}
						/>
					)}
					{userLoading && <Loader fullscreen={true} />}
					{user && !admin && !store && <Loader fullscreen={true} />}
				</>
			)}
		</ChakraProvider>
	);
}
