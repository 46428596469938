import {
	type ActionCodeSettings,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from "firebase/auth";
import type { User } from "../types";
import { auth } from "./config";

export const createUser = async (email: string, password: string) =>
	await createUserWithEmailAndPassword(auth, email, password);

export const sendForgotPasswordEmail = async (
	email: string,
	actionCodeSettings: ActionCodeSettings,
) => await sendPasswordResetEmail(auth, email, actionCodeSettings);

export const sendVerificationEmail = async (
	user: User,
	actionCodeSettings: ActionCodeSettings,
) => await sendEmailVerification(user, actionCodeSettings);

export const signIn = async (email: string, password: string) =>
	await signInWithEmailAndPassword(auth, email, password);
